<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-import bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex">
              <h4 class="pr-2">{{ dataImportpointbyid.original_file_name }}</h4>
              <div
                v-if="
                  dataImportpointbyid.action_result.status == 'Failed' &&
                  dataImportpointbyid.import_result.totals_success > 0
                "
              >
                <template v-for="(item, index) in status">
                  <a-tag
                    v-if="dataImportpointbyid.action_result.status == item.name"
                    :key="index"
                    :color="item.color == 'default' ? null : 'green'"
                    class="tag-status"
                  >
                    <a-badge
                      status="green"
                      text="
                    Approved
                  "
                    />
                  </a-tag>
                </template>
              </div>
              <div v-else>
                <template v-for="(item, index) in status">
                  <a-tag
                    v-if="dataImportpointbyid.action_result.status == item.name"
                    :key="index"
                    :color="item.color == 'default' ? null : item.color"
                    class="tag-status"
                  >
                    <a-badge
                      :status="item.color"
                      :text="
                        textStatus(dataImportpointbyid.action_result.status)
                      "
                    />
                  </a-tag>
                </template>
              </div>
              <!-- <template v-for="(item, index) in status">
                <a-tag
                  v-if="dataImportpointbyid.action_result.status == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge
                    :status="item.color"
                    :text="textStatus(dataImportpointbyid.action_result.status)"
                  />
                </a-tag>
              </template> -->
            </div>
            <div v-if="dataImportpointbyid.action_result.status == 'Failed'">
              <a-popover
                v-model:visible="popnoti"
                trigger="click"
                placement="bottomRight"
              >
                <template #content>
                  <div class="p-3">
                    <div class="d-flex">
                      <img class="mr-3" src="@/assets/icons/check.svg" />{{
                        dataImportpointbyid.import_result.totals_success
                      }}
                      records imported
                    </div>
                    <div class="d-flex">
                      <img class="mr-3" src="@/assets/icons/xmark.svg" />{{
                        dataImportpointbyid.import_result.totals_fail
                      }}
                      error records
                    </div>
                    <div
                      class="d-flex justify-content-between mt-2"
                      style="width: 240px"
                    >
                      <div class="text-noti pt-1">Found a failed record.</div>
                      <a class="ml-3 text-link" @click="exportFailedRecord()"
                        >Dowload</a
                      >
                    </div>
                  </div>
                </template>
                <a><exclamation-circle-outlined class="i-noti" /></a>
              </a-popover>
            </div>
          </div>
          <div class="flex-auto overflow-y-auto">
            <div class="bg-con">
              <div class="d-flex">
                <div class="mr-2">Create Date :</div>
                <div>
                  {{
                    formatDate(dataImportpointbyid.created_date, 'DD/MMM/YYYY')
                  }}
                </div>
              </div>
              <div class="d-flex">
                <div class="mr-2">Created By :</div>
                <div>
                  {{
                    dataImportpointbyid.auditable
                      ? dataImportpointbyid.auditable.name
                      : '-'
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <h4 class="mx-15 pr-2">Import List</h4>
              <a-badge
                :count="dataImportpointbyid.data_items.length"
                :overflow-count="999999"
                :class="type == 'default' ? null : 'count-' + 'list'"
              />
            </div>
            <div class="mx-15 mt-2">
              <a-collapse
                v-for="(item, index) of dataImportpointbyid.data_items"
                :key="`taxindex1-${index}`"
                :bordered="false"
                :class="index % 2 == 0 ? 'bg-gray' : 'bg-l-gray'"
              >
                <!-- <template #expandIcon="{ isActive }">
                  <down-outlined :rotate="isActive ? 180 : 0" />
                </template> -->
                <a-collapse-panel
                  :key="`taxindex2-${index}`"
                  :header="item.user_id"
                  :show-arrow="false"
                >
                  <template #extra>
                    <div v-if="item.points > 0" class="point-plus">
                      +{{ item.points.toLocaleString() }}
                    </div>
                    <div v-else class="point-minus">
                      {{ item.points.toLocaleString() }}
                    </div>
                  </template>
                  <hr class="mb-2" />
                  <div class="d-flex">
                    <div class="text-gray">Reason :</div>
                    <div class="pl-3">{{ item.reason }}</div>
                  </div>
                  <div class="d-flex mt-2">
                    <div class="text-gray mr-2">Notification :</div>
                    <template v-for="(itemstatus, index) in statusnoti">
                      <a-tag
                        v-if="item.send_notification == itemstatus.name"
                        :key="index"
                        :color="
                          itemstatus.color == 'default'
                            ? null
                            : itemstatus.color
                        "
                        class="tag-status"
                      >
                        <a-badge
                          :status="itemstatus.color"
                          :text="item.send_notification"
                        />
                      </a-tag>
                    </template>
                    <div class="status">
                      <span class="text-gray mr-2">Channel :</span>
                      <a-tag color="purple" class="tag-status">
                        <a-badge status="purple" text="App Notification" />
                      </a-tag>
                    </div>
                  </div>
                  <div class="d-flex mt-2">
                    <div class="text-gray">Message :</div>
                    <div class="pl-3">{{ item.notification_message }}</div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
          <!-- <div
            class="px-4 py-3 m-3 noti"
            v-if="dataImportpointbyid.action_result.status == 'Failed'"
          >
            <div class="d-flex justify-content-between">
              <div>
                <exclamation-circle-outlined class="i-noti" />
                <span class="pl-3 fraud"
                  >Found a failed record. would you like to export?</span
                >
              </div>
              <div>
                <a class="ml-3 text-link" @click="exportFailedRecord()"
                  >Download</a
                >
              </div>
            </div>
          </div> -->
          <div
            v-if="dataImportpointbyid.action_result.status == 'Create'"
            class="p-3"
          >
            <div class="mb-2">Remark :</div>
            <a-textarea
              v-model:value="remark"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
            <div v-if="requireRemark && !remark" class="mt-1 text-danger">
              * Please enter a reason.
            </div>
          </div>
          <div
            v-if="
              dataImportpointbyid.action_result.status == 'Completed' ||
              dataImportpointbyid.action_result.status == 'Reject' ||
              dataImportpointbyid.action_result.status == 'Failed'
            "
          >
            <div class="bg-con">
              <a-row>
                <a-col :span="6">
                  <div>Remark :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataImportpointbyid.action_result.reason
                        ? dataImportpointbyid.action_result.reason
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div>Responded date :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataImportpointbyid.action_result.approve_reject_date
                        ? formatDate(
                            dataImportpointbyid.action_result
                              .approve_reject_date,
                            'DD/MMM/YYYY',
                            this.isTimezone * -1,
                          )
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div>Respondent :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataImportpointbyid.action_result.approve_reject_by
                        ? dataImportpointbyid.action_result.approve_reject_by
                            .name
                        : '-'
                    }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="d-flex justify-content-between px-4 py-3">
            <div>
              <a-button
                v-if="dataImportpointbyid.action_result.status == 'Create'"
                @click="RejectImportsAdjustpoints()"
                type="danger"
                class="mr-3 btn-create"
                >Reject</a-button
              >
            </div>
            <div
              :class="
                dataImportpointbyid.action_result.status == 'Create'
                  ? 'd-flex'
                  : 'w-maxmax'
              "
            >
              <a-button
                :class="
                  dataImportpointbyid.action_result.status == 'Create'
                    ? 'btn-create'
                    : 'w100'
                "
                type="primary-2"
                @click="dismiss(true)"
              >
                Close
              </a-button>
              <a-button
                v-if="dataImportpointbyid.action_result.status == 'Create'"
                type="success"
                class="btn-create ml-3"
                @click="ApprovedImportsAdjustpoints()"
              >
                Approve
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    class="modal-set-expire"
    :visible="modalReject"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="h-modal">
      <div>Reject reason</div>
    </div>
    <div class="border-t-2">
      <div class="font-modal-delete px-4 mb-5">
        <div class="pt-4">Reason</div>
        <a-textarea
          v-model:value="value2"
          :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </div>
      <div class="p-4 border-t-2 modal-footer">
        <a-button type="primary" @click="handleCancel()">Save</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
// helper
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import CacheHelper from '@/helper/CacheHelper.js'
// import ConfigManager from '@/config/ConfigManager.js'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
// var Cache = new CacheHelper(ConfigManager.getCacheType())
import { config } from '@/config/bzbsconfig.js'
// import _ from 'lodash'
export default {
  name: 'DisplayFields',
  components: {
    ExclamationCircleOutlined,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataImportpointbyid: {
      type: Object,
    },
  },
  computed: {},
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      modalReject: false,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      remark: '',
      isErrorName: false,
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'Completed',
          color: 'green',
        },
        {
          id: 4,
          name: 'L1Approved',
          color: 'orange',
        },
        {
          id: 5,
          name: 'Failed',
          color: 'red',
        },
      ],
      statusnoti: [
        {
          id: 1,
          name: 'No',
          color: 'red',
        },
        {
          id: 2,
          name: 'no',
          color: 'red',
        },
        {
          id: 3,
          name: 'Yes',
          color: 'green',
        },
        {
          id: 4,
          name: 'yes',
          color: 'green',
        },
      ],
      dataImport: {},
      checkNoti: true,
      popnoti: false,
      requireRemark: false,
    }
  },
  watch() {},
  mounted() {},
  created: function () {
    // set state when created
  },
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Waiting'
        case 'L1Approved':
          return 'Processing'
        case 'Completed':
          return 'Approved'
        case 'Failed':
          return 'Failed'
        case 'Reject':
          return 'Rejected'
      }
    },
    disabledNoti() {
      this.checkNoti = false
      this.popnoti = false
    },
    exportFailedRecord() {
      window.location = this.dataImportpointbyid.import_result.fail_blob_url
    },
    ApprovedImportsAdjustpoints() {
      this.$store.commit('setLoading', true)
      this.PostApprovedImportsAdjustpoints().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchImportsPoints')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostApprovedImportsAdjustpoints() {
      var id = {
        appid: this.dataImportpointbyid.app_id,
        id: this.dataImportpointbyid.id,
        reason: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApprovedImportsAdjustpoints(id)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    RejectImportsAdjustpoints() {
      if (!this.remark) {
        this.requireRemark = true
      } else {
        this.requireRemark = false
        this.$store.commit('setLoading', true)
        this.PostRejectImportsAdjustpoints().then(() => {
          this.$store.commit('setLoading', false)
          this.$success({
            title: 'Success',
            okType: 'default',
            centered: true,
            onOk: () => {
              this.$emit('clicksearchImportsPoints')
              this.$emit('handleClose')
              this.remark = ''
            },
          })
        })
      }
    },
    PostRejectImportsAdjustpoints() {
      var id = {
        appid: this.dataImportpointbyid.app_id,
        id: this.dataImportpointbyid.id,
        reason: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectImportsAdjustpoints(id)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    dismiss: function () {
      this.remark = ''
      this.requireRemark = false
      this.$emit('handleClose')
    },
    handleCancel() {
      this.modalReject = false
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .bg-con {
    margin: 15px;
    padding: 15px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }

  .ant-btn {
    width: 100%;
  }

  .input-error {
    border-color: #ff5252 !important;
  }

  .text-error {
    color: #ff5252;
  }

  .pd-t-21 {
    padding-top: 21px;
  }
}

.w100 {
  width: 100% !important;
}

.pointer {
  cursor: pointer;
}

.w-log-import {
  width: 40rem;
  .mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .bg-title {
    background-color: #f3f6f9;
    border-bottom: 4px solid #ecedf3;
  }

  .ant-collapse-borderless.bg-gray {
    border-radius: 0px !important;
    background-color: #e3e3e3 !important;
  }
  .ant-collapse-borderless.bg-l-gray {
    border-radius: 0px !important;
    background-color: #f5f5f5 !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #c9c9c9 !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 15px 8px 15px !important;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 12px 0px !important;
    padding-right: 40px !important;
  }
}

.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}

.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}

.cursor-disabled {
  cursor: not-allowed;
}

.i-noti {
  color: #ff4d4f !important;
  font-size: 18px;
}

.noti {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #ecedf3;
  background-color: #fff;
  border-radius: 6px;
}

.text-noti {
  color: #ff4d4f;
  font-size: 12px;
}
</style>
