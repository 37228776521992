<template>
  <AppLayout :disable-menu="false" :menu-callcenter="true">
    <TitleBreadcrumbs class="ml-5 mt-4" title="FAQ" />
    <div class="FAQ flex-1 w-full px-4">
      <div class="mb-2 b-6 px-3 bg-white">
        <div class="py-2 d-flex justify-content-between gab-10">
          <!-- <a-button
            v-if="datapermission.includes('FAQ-Import')"
            type="teriary"
            @click="toggleImport()"
            ><file-excel-outlined />Import File</a-button
          >
          <a-button
            v-if="datapermission.includes('FAQ-Download')"
            type="primary"
            @click="toggleImport()"
            ><download-outlined />Download Template</a-button
          > -->
          <a-select
            class="select-app"
            v-model:value="application"
            placeholder="All Application"
            show-search
            :options="application_option"
            @change="PostGetFAQ()"
          />
          <div class="d-flex gap-2">
            <div
              v-if="
                permissioncallcenter.find(x =>
                  x.features.find(y => y.name == 'Call-Center-FAQ-Adjust'),
                )
              "
            >
              <a-button
                type="primary"
                class="btn-icon"
                @click="createNewSubject(dataFAQ[0].appId)"
              >
                Create Question
              </a-button>
            </div>
            <a-popover
              v-model:visible="popmore"
              trigger="click"
              placement="bottomRight"
            >
              <template #content>
                <div class="action-history">
                  <div
                    v-if="
                      permissioncallcenter.find(x =>
                        x.features.find(
                          y => y.name == 'Call-Center-FAQ-Adjust',
                        ),
                      )
                    "
                    class="filter-action"
                    @click="toggleImport()"
                  >
                    <file-excel-outlined />Import File
                  </div>
                  <div
                    v-if="
                      permissioncallcenter.find(x =>
                        x.features.find(
                          y => y.name == 'Call-Center-FAQ-Adjust',
                        ),
                      )
                    "
                    class="filter-action"
                    @click="gettemplate()"
                  >
                    <download-outlined />Download Template
                  </div>
                </div>
              </template>
              <a-tooltip placement="bottom">
                <template #title>
                  <div>More</div>
                </template>
                <a-button class="hover btn-icon">
                  <img src="@/assets/icons/more.svg" alt />
                </a-button>
              </a-tooltip>
            </a-popover>
          </div>
        </div>
      </div>
      <div class="table-scroll" @scroll="handleScroll">
        <div v-if="dataFAQ.length == 0" class="mainNodata text-webkit-center">
          <img :src="imgEmpty" />
          <div class="nodata">No Data</div>
        </div>
        <!-- <VLoadingTable :value="overlaytable" /> -->
        <a-collapse
          v-for="(data, index) of dataFAQ"
          :key="`index-${index}`"
          :style="customStyle"
        >
          <template #expandIcon="{ isActive }">
            <caret-right-outlined :rotate="isActive ? 90 : 0" />
          </template>
          <a-collapse-panel>
            <template #header>
              <a
                v-if="
                  permissioncallcenter.find(x =>
                    x.features.find(y => y.name == 'Call-Center-FAQ-Adjust'),
                  )
                "
                @click="$event => toggleDisplayFieldsEdit($event, data)"
                ><h4 class="text-link">{{ data.fAQ }}</h4></a
              >
              <h4 v-else>{{ data.fAQ }}</h4>
            </template>
            <!-- <template #extra>
              <div>
                <a-popover
                  v-if="!data.editans && datapermission.includes('FAQ-Delete')"
                  trigger="click"
                  placement="bottomRight"
                >
                  <template #content>
                    <div class="filter-action false" @click="deleteFaq(data)">
                      <delete-outlined /> Delete Question
                    </div>
                    <div class="filter-action" @click="toggleRemoveAns(data)">
                      Remove Answer
                    </div>
                  </template>
                  <a-button
                    class="btn-icon"
                    @click="$event => toggleDeleteFaq($event, data)"
                    ><setting-outlined
                  /></a-button>
                </a-popover>
                <a-button v-if="data.editans" @click="cancelEditAns(data)"
                  >Cancel</a-button
                >
              </div>
            </template> -->
            <div class="h-col">
              <div
                v-for="(data_ans, index2) of data.answer_details"
                :key="`index2-${index2}`"
              >
                <div class="pt-3">
                  <div class="content-col">
                    <div class="mb-3 d-flex justify-content-between">
                      <div>{{ data_ans.faq_ans }}</div>
                      <a-button v-if="data.editans" type="danger" class="mr-5"
                        ><close-circle-outlined />Remove answer</a-button
                      >
                    </div>
                    <a-image
                      v-if="
                        data_ans.faq_img_path && data_ans.faq_img_path != 'null'
                      "
                      :width="120"
                      :height="120"
                      :src="data_ans.faq_img_path"
                    />
                  </div>
                  <hr
                    v-if="
                      data.answer_details.slice(-1)[0].faq_ans !=
                      data_ans.faq_ans
                    "
                  />
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <DisplayFieldsCreateQuestion
      ref="CreateQuestion"
      :value="showModalSubject"
      @PostGetFAQ="PostGetFAQ()"
      @handleClose="showModalSubject = false"
    >
    </DisplayFieldsCreateQuestion>
    <DisplayFieldEditQuestion
      ref="EditQuestion"
      :value="showModalEditQuestion"
      @PostGetFAQ="PostGetFAQ()"
      @handleClose="showModalEditQuestion = false"
    >
    </DisplayFieldEditQuestion>
    <a-modal
      v-model:visible="showModalImport"
      wrap-class-name="modal-import"
      width="450px"
      title="Import File"
      :centered="true"
      :closable="false"
      okText="Confirm"
      @cancel="closeModal"
      @ok="importFAQ()"
    >
      <!-- <div class="mb-2">Appliaction <label class="false">*</label> :</div>
      <a-select
        class="select-search"
        v-model:value="statussearch"
        placeholder="All Application"
        :options="status_option"
      /> -->
      <div class="d-flex mt-3">
        <div>File</div>
        <div class="text-gray ml-1">(format .xlsx) :</div>
      </div>
      <a-upload
        v-model:file-list="fileList"
        name="file"
        class="import"
        :beforeUpload="[
          function () {
            false
          },
        ]"
        :headers="headers"
        @change="handleChange"
      >
        <a-button class="mb-3" type="teriary"
          ><file-excel-outlined />Import File</a-button
        >
      </a-upload>
    </a-modal>
  </AppLayout>
</template>
<script>
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import DisplayFieldsCreateQuestion from '@/module/CallCenter/component/DisplayFieldsCreateQuestion.vue'
import DisplayFieldEditQuestion from '@/module/CallCenter/component/DisplayFieldsEditQuestion.vue'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { config } from '@/config/bzbsconfig.js'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    DisplayFieldsCreateQuestion,
    DisplayFieldEditQuestion,
    TitleBreadcrumbs,
    AppLayout,
  },
  data: function () {
    return {
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      demoimg: require('@/assets/imgs/img-faq-demo.png'),
      permissioncallcenter:
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket')),
      page: 1,
      check: false,
      activeKey: null,
      expandIconPosition: 'right',
      showModalSubject: false,
      showModalEditSubject: false,
      showModalImport: false,
      active: true,
      statussearch: 'all',
      status_option: [
        {
          label: 'All Application',
          value: 'all',
        },
        {
          label: 'Abbot',
          value: 'Abbot',
        },
      ],
      fileList: [],
      showModalEditQuestion: false,
      dataFAQ: [],
      dataDetail: {},
      editans: false,
      dataTemplate: [],
      template_csv: '',
      template_xlsx: '',
      popmore: false,
      application: '318353931566530|Buzzebees',
      application_option: [],
      appId_list: JSON.parse(localStorage.getItem('applist')),
    }
  },
  computed: {},
  mounted() {
    if (this.$store.state.permissionPage) {
      this.PostGetFAQ().then(() => {
        this.$store.commit('setLoading', false)
      })
    }
  },
  created() {
    this.application_option = this.appId_list.map(item => {
      return {
        label: item.appName,
        value: `${item.appId}|${item.appName}`,
      }
    })
  },
  methods: {
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable && this.isloadmore) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.PostGetFAQ().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    PostGetFAQ() {
      this.$store.commit('setLoading', true)
      var params = {
        page_number: this.page,
        limit: 1000,
        appid: this.application.split('|')[0],
      }
      this.isloadmore = true
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostGetFAQ(params)
          .then(res => {
            if (this.page == 1) {
              this.dataFAQ = []
            }
            _.forEach(res.data.result, item => {
              this.dataFAQ.push(item)
            })
            if (res.data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    gettemplate() {
      this.getImportsTemplates().then(() => {
        window.location = this.template_xlsx
      })
    },
    getImportsTemplates() {
      this.$store.commit('setLoading', true)
      let appid = this.application.split('|')[0]
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetImportsTemplatesFAQ(appid).subscribe(
          res => {
            this.dataTemplate = res.result
            this.template_xlsx = this.dataTemplate.excel_file_url
            this.$store.commit('setLoading', false)
            resolve(this.dataprivacy)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    importFAQ() {
      this.showModalImport = false
      this.postImportsFAQ().then(() => {
        this.PostGetFAQ()
      })
    },
    postImportsFAQ() {
      this.$store.commit('setLoading', true)
      console.log(this.fileList[0].originFileObj)
      var importfaq = {
        appid: this.application.split('|')[0],
        file: this.fileList[0].originFileObj,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostImportsFAQ(importfaq)
          .then(res => {
            this.showModalImport = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {},
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    toggleImport() {
      this.popmore = false
      this.showModalImport = !this.showModalImport
    },
    closeModal() {
      this.statussearch = null
      this.fileList = []
    },
    clicktest() {
      this.check = !this.check
    },
    createNewSubject(appid) {
      this.$refs.CreateQuestion.data(appid)
      this.showModalSubject = !this.showModalSubject
    },
    toggleDisplayFieldsEdit(event, data) {
      event.stopPropagation()
      this.PostGetFAQ().then(() => {
        this.$refs.EditQuestion.data(data)
        this.showModalEditQuestion = true
        this.$store.commit('setLoading', false)
      })
    },
    PutDeleteFaq(id) {
      var params = {
        appid: this.application.split('|')[0],
        ints: [id],
      }
      BzbsUser.apiPutDeleteFaq(params).then(() => {
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.PostGetFAQ()
          },
        })
      })
    },
    deleteFaq(data) {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you sure you want to delete this question?',
        content:
          'This action cannot be undone. Deleting the question will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PutDeleteFaq(data.iD)
        },
        onCancel: () => {},
      })
    },
    toggleDeleteFaq(event) {
      event.stopPropagation()
    },
    toggleRemoveAns(data) {
      data.editans = true
    },
    cancelEditAns(data) {
      data.editans = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.FAQ {
  .table-scroll {
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
  .gab-10 {
    gap: 10px;
  }
  .select-search {
    width: 200px;
  }
  .img-demo {
    width: 150px;
    height: 150px;
    border-radius: 6px;
  }
  .ant-table-tbody {
    display: none !important;
  }
  .ant-collapse {
    margin-top: 10px !important;
  }
  .ant-collapse-header {
    background-color: #ffffff !important;
    border-radius: 12px !important;
    padding: 16px !important;
    align-items: center !important;
  }
  .ant-collapse-content-box {
    background-color: #f9f9f9 !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none !important;
  }
  // .ant-image-img {
  //   width: unset !important;
  //   height: unset !important;
  // }
  .icon {
    margin-right: 12px;
    width: 12px;
    color: #797979;
  }
  .content-col {
    padding-left: 32px;
  }
  .border-tb {
    border-bottom: 1px solid #f0f0f0;
  }
  .createsub {
    position: absolute;
    right: 22px;
    top: 130px;
  }
  .ant-btn-icon-only > .anticon {
    justify-content: end !important;
  }
  .ant-btn-text:hover,
  .ant-btn-text:focus {
    background: #fff !important;
    border-color: #fff !important;
    box-shadow: none !important;
  }
}
.modal-import {
  .ant-upload.ant-upload-select {
    float: right !important;
    margin-top: -32px !important;
  }
  .ant-upload-list-text-container {
    margin-top: 20px !important;
  }
  .select-search {
    width: 390px;
  }
}
</style>
