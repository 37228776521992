<template>
  <a-card class="w-full min-h-full redeem">
    <nav class="filter-nav d-flex justify-content-between mb-3">
      <div class="d-flex gab-10">
        <a-input-group compact>
          <a-select
            class="select-search"
            v-model:value="optionssearch"
            :options="options_search"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @keyup="enterFilter($event)"
            :allowClear="true"
          >
            <template #prefix>
              <a @click="applyFilter">
                <search-outlined />
              </a>
            </template>
            <template #suffix>
              <a @click="toggleAdvaceSearch">
                <img
                  class="ico-filter"
                  :class="{ active: checkActive }"
                  src="@/assets/icons/faders.svg"
                />
              </a>
              <FilterSearch
                ref="FilterSearch"
                v-model:value="showAdvaceSearch"
                :FilterConfig="optionlist"
                @applyFilter="applyFilter($event)"
                @handleClose="showAdvaceSearch = false"
              />
            </template>
          </a-input>
        </a-input-group>
        <!-- <a-select
          v-model:value="campaigntype"
          :options="options_campaigntype"
          @change="handleType"
        /> -->
        <!-- <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch"
            ><search-outlined /> Search</a-button
          >
        </div> -->
      </div>
      <div class="d-flex gab-10">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <a-button
          v-show="setperrmissionexport"
          type="teriary"
          @click="exportReport"
        >
          <file-excel-outlined /> Export as excel
        </a-button>
      </div>
    </nav>
    <div class="wrap-table" @scroll="handleScroll">
      <VLoadingTable :value="overlaytable" />
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataRedeem"
        :columns="displayFields"
        :pagination="false"
      >
        <template #campaign="{ record }">
          <div>
            {{ record.campaign_name }}
            <label class="text-id">{{ record.campaign_id }}</label>
          </div>
          <div></div>
        </template>
        <template #campaign_type="{ text, record }">
          <div class="fw-500">{{ text }}</div>
          <div class="iswinner">
            <div
              class="winner"
              v-show="text === 'Draw' && record.has_winner && record.is_winner"
            >
              WINNER
            </div>
            <div
              class="not-winner"
              v-show="
                text === 'Draw' &&
                record.has_winner == true &&
                record.is_winner == false
              "
            >
              Not a winner
            </div>
            <div
              class="not-yet"
              v-show="
                text === 'Draw' && !record.has_winner && !record.is_winner
              "
            >
              Not yet annocnced
            </div>
          </div>
        </template>
        <template #status="{ record }">
          <template v-for="(itemstatus, index) in status">
            <a-tag
              v-if="record.status == itemstatus.ori_name"
              :key="index"
              :color="itemstatus.color == 'default' ? null : itemstatus.color"
              class="tag-status"
            >
              <a-badge :status="itemstatus.color" :text="itemstatus.name" />
            </a-tag>
          </template>
        </template>
        <template #redeem_date="{ record }">
          {{ formatDate(record.redeem_date, 'DD/MMM/YYYY - HH:mm') }}
        </template>
        <template #used_date="{ text, record }">
          <div class="d-flex">
            <div>{{ text }}</div>
            <a
              v-if="datapermissionbyapp.includes('User-List-Redeem-Edit')"
              class="text-link ml-2"
              @click="postExpireCampaign(record)"
              ><edit-outlined
            /></a>
          </div>
        </template>
        <template #arranged="{ record }">
          <div>{{ record.arranged }}</div>
        </template>
        <template #arranged_date="{ record }">
          <div>{{ record.arranged_date }}</div>
        </template>
      </a-table>
    </div>
    <a-modal
      class="modal-set-expire"
      :visible="modalExpire"
      @cancel="handleCancel"
      okText="Save"
      :width="400"
      :centered="true"
      @ok="PostponeExpire()"
      :closable="false"
    >
      <template #title>
        Campaign ID : {{ this.dataRecord.campaign_id }} edit use date
      </template>
      <div class="mb-2">Set to</div>
      <a-date-picker
        style="width: 340px"
        v-model:value="expirydate"
        placeholder="Select Expiry"
        :showToday="false"
        :disabled-date="disabledDate"
      />
    </a-modal>
    <DisplayFields
      ref="selectall"
      :value="showDisplayFields"
      :headers="columns"
      @apply="applyDisplayFields($event)"
      @handleClose="showDisplayFields = false"
    >
    </DisplayFields>
    <ExportModal
      :visible="showExportModal"
      @handleSubmit="handleExportModal($event)"
      @handleClose="showExportModal = false"
    ></ExportModal>
    <PrepareModal
      :visible="showPrepareModal"
      @handleClose="showPrepareModal = false"
    ></PrepareModal>
  </a-card>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'
// content components
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import FilterSearch from '@/components/Filter/FilterSearch.vue'
// import DateFilter from '@/module/User/component/DateFilter.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import { EditOutlined } from '@ant-design/icons-vue'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserRedeem',
  components: {
    DisplayFields,
    ExportModal,
    PrepareModal,
    EditOutlined,
    FilterSearch,
  },
  props: {
    dataapp: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      showDisplayFields: false,
      overlaytable: false,
      setperrmissionexport: true,
      dataRedeem: [],
      isVietnam: config.businessRule.webFor.VN,
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      searchObject: {
        campaign_id: '',
        campaign_name: '',
        agency_name: '',
        category_name: '',
        serial: '',
        parcel_no: '',
        status: '',
        startdate: '',
        enddate: '',
      },
      optionssearch: 'campaignid',
      options_search: [
        {
          label: 'Campaign ID',
          value: 'campaignid',
        },
        {
          label: 'Campaign Name',
          value: 'campaignname',
        },
        {
          label: 'Agency',
          value: 'agency',
        },
        {
          label: 'Category',
          value: 'category',
        },
        {
          label: 'Serial',
          value: 'serial',
        },
        {
          label: 'Parcel',
          value: 'parcel',
        },
      ],
      optionlist: {
        status: true,
        daterange: true,
        options: [
          {
            label: 'All Campaign Type',
            value: '',
          },
          {
            label: 'Draw',
            value: 'Draw',
          },
          {
            label: 'Donate',
            value: 'Donate',
          },
          {
            label: 'Free',
            value: 'Free',
          },
          {
            label: 'Deal',
            value: 'Deal',
          },
          {
            label: 'Interface',
            value: 'Interface',
          },
        ],
      },
      options_campaigntype: [
        {
          label: 'All Campaign Type',
          value: '',
        },
        {
          label: 'Draw',
          value: 'Draw',
        },
        {
          label: 'Donate',
          value: 'Donate',
        },
        {
          label: 'Free',
          value: 'Free',
        },
        {
          label: 'Deal',
          value: 'Deal',
        },
        {
          label: 'Interface',
          value: 'Interface',
        },
      ],
      search: '',
      params: {
        name: '',
        description: '',
        filter: {
          user_id: '',
          appid: '',
          campaign_id: null,
          campaign_name: '',
          agency_name: '',
          category_name: '',
          serial: '',
          parcel_no: '',
          campaign_type: '',
          date_filter_type: null,
          is_shipped: null,
          delivered: null,
          is_used: null,
          start_date: null,
          end_date: null,
        },
      },
      page: 1,
      columns: [
        {
          title: 'Campaign Type',
          slots: { customRender: 'campaign_type' },
          dataIndex: 'campaign_type',
          key: 'campaign_type',
          width: 175,
          align: 'left',
        },
        {
          title: 'Campaign',
          dataIndex: 'campaign',
          key: 'campaign',
          slots: { customRender: 'campaign' },
          // sorter: (a, b) => a.campaignid - b.campaignid,
          // sortDirections: ['descend'],
          width: 400,
          align: 'left',
        },
        // {
        //   title: 'Campaign Name',
        //   dataIndex: 'campaign_name',
        //   key: 'campaign_name',
        //   align: 'left',
        //   width: 300,
        // },

        {
          title: 'Agency',
          dataIndex: 'agency_name',
          key: 'agency_name',
          width: 180,
          align: 'left',
        },
        {
          title: 'Category',
          dataIndex: 'category',
          key: 'category',
          width: 120,
          align: 'left',
        },
        {
          title: 'Serial',
          dataIndex: 'serial',
          key: 'serial',
          width: 120,
          align: 'left',
        },
        {
          title: 'Points Use',
          dataIndex: 'point_used',
          key: 'point_used',
          width: 150,
          align: 'right',
        },
        {
          title: 'Redeem Date',
          dataIndex: 'redeem_date',
          key: 'redeem_date',
          slots: { customRender: 'redeem_date' },
          width: 220,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 120,
          align: 'left',
        },
        {
          title: 'Use',
          dataIndex: 'used',
          key: 'used',
          width: 120,
        },
        {
          title: 'Use Date',
          dataIndex: 'used_date',
          slots: { customRender: 'used_date' },
          key: 'used_date',
          width: 260,
          align: 'left',
        },
        {
          title: 'Arrange',
          dataIndex: 'arranged',
          slots: { customRender: 'arranged' },
          key: 'arranged',
          width: 120,
        },
        {
          title: 'Arrange Date',
          dataIndex: 'arranged_date',
          slots: { customRender: 'arranged_date' },
          key: 'arranged_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Shipping',
          dataIndex: 'shipped',
          key: 'shipped',
          align: 'center',
        },
        {
          title: 'Shipping Date',
          dataIndex: 'shipping_date',
          key: 'shipping_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Parcel Number',
          dataIndex: 'parcel',
          key: 'parcel',
          width: 180,
          align: 'left',
        },
      ],
      campaigntype: '',
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      showExportModal: false,
      showPrepareModal: false,
      modalExpire: false,
      expirydate: null,
      dataRecord: [],
      showAdvaceSearch: false,
      checkActive: false,
      status: [
        {
          id: 1,
          name: 'Redeemed',
          ori_name: 'Redeem',
          color: 'green',
        },
      ],
    }
  },
  beforeCreate() {
    this.displayFields = _.cloneDeep(this.columns)
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'redeem') {
        this.displayFields = _.cloneDeep(this.columns)
        this.showAdvaceSearch = false
        this.$refs.FilterSearch.resetFilter()
        this.checkActive = false
        this.applyFilter()
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'redeem') {
      this.displayFields = _.cloneDeep(this.columns)
      this.showAdvaceSearch = false
      this.applyFilter()
      this.$refs.FilterSearch.resetFilter()
      this.checkActive = false
      this.overlay = false
    }
  },
  created() {},
  methods: {
    enterFilter(key) {
      if (key.key == 'Enter' && !this.showAdvaceSearch) {
        this.applyFilter()
      }
    },
    disabledDate(startValue) {
      var date = new Date()
      return startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
    },
    applyFilter(filter) {
      if (
        filter?.status != null ||
        (filter?.startdate?.length > 0 && filter?.enddate?.length > 0)
      ) {
        this.checkActive = true
      } else {
        this.checkActive = false
      }
      this.showAdvaceSearch = false
      this.$store.commit('setLoading', true)
      this.page = 1
      this.searchObject.campaign_id =
        this.optionssearch == 'campaignid' ? this.search : ''
      this.searchObject.campaign_name =
        this.optionssearch == 'campaignname' ? this.search : ''
      this.searchObject.agency_name =
        this.optionssearch == 'agency' ? this.search : ''
      this.searchObject.category_name =
        this.optionssearch == 'category' ? this.search : ''
      this.searchObject.serial =
        this.optionssearch == 'serial' ? this.search : ''
      this.searchObject.parcel_no =
        this.optionssearch == 'parcel' ? this.search : ''
      this.searchObject.status = filter?.status ? filter.status : ''
      this.searchObject.startdate = filter?.startdate
        ? dayjs(filter?.startdate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.searchObject.enddate = filter?.enddate
        ? dayjs(filter?.enddate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.showDateFilter = false
      this.callAPiReportRedeem().then(() => {
        // if (!this.isVietnam) {
        //   this.displayFields = this.displayFields.filter(
        //     x => x.key != 'arranged' && x.key != 'arranged_date',
        //   )
        // } else {
        //   return this.displayFields
        // }
        this.$store.commit('setLoading', false)
      })
    },
    callAPiReportRedeem() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserRedeems(
          this.$route.params.id,
          this.page,
          this.searchObject.campaign_id,
          this.searchObject.campaign_name,
          this.searchObject.agency_name,
          this.searchObject.category_name,
          this.searchObject.serial,
          this.searchObject.parcel_no,
          this.searchObject.status,
          this.searchObject.startdate,
          this.searchObject.enddate,
        ).subscribe(
          data => {
            console.log(data)
            if (this.page == 1) {
              this.dataRedeem = []
            }
            _.forEach(data.result, item => {
              item.description = item.description ? item.description : '-'
              item.parcel = item.parcel ? item.parcel : '-'
              item.shipping_date = item.shipping_date
                ? (item.shipping_date = moment(item.shipping_date).format(
                    'DD/MMM/YYYY - HH:mm',
                  ))
                : '-'
              item.used = item.used ? 'Yes' : 'No'
              item.shipped = item.shipped ? 'Yes' : 'No'
              item.used_date = item.used_date
                ? moment(item.used_date).format('DD/MMM/YYYY - HH:mm')
                : '-'
              item.arranged = item.arranged ? 'Yes' : 'No'
              item.arranged_date = item.arranged_date
                ? moment(item.arranged_date).format('DD/MMM/YYYY - HH:mm')
                : '-'
              item.point_used = item.point_use
                ? item.point_used.toLocaleString('en-US')
                : '-'
              this.dataRedeem.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.setpermissionexport()
            this.$store.commit('setLoading', false)
            resolve(this.dataRedeem)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    toggleAdvaceSearch() {
      this.showAdvaceSearch = !this.showAdvaceSearch
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          console.log('loadMore', this.page)
          this.callAPiReportRedeem().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    exportReport() {
      this.showExportModal = true
      console.log(this.showExportModal)
    },
    handleExportModal(value) {
      var params = {
        filename: value.filename,
        description: value.description,
      }
      this.callAPiExportRedeem(params).then(res => {
        console.log(res)
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    callAPiExportRedeem(params) {
      this.params.description = params.description
      this.params.filter.appid = ConfigManager.getAppId()
      this.params.name = params.filename
      this.params.filter.user_id = this.$route.params.id
      ;(this.params.filter.campaign_id = this.searchObject.campaign_id
        ? this.searchObject.campaign_id
        : null),
        (this.params.filter.campaign_name = this.searchObject.campaign_name),
        (this.params.filter.agency_name = this.searchObject.agency_name),
        (this.params.filter.category_name = this.searchObject.category_name),
        (this.params.filter.serial = this.searchObject.serial),
        (this.params.filter.parcel_no = this.searchObject.parcel_no),
        (this.params.filter.campaign_type = this.searchObject.status
          ? this.searchObject.status
          : null),
        (this.params.filter.start_date = this.searchObject.startdate
          ? this.searchObject.startdate
          : null),
        (this.params.filter.end_date = this.searchObject.enddate
          ? this.searchObject.enddate
          : null)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRedeemsExport(this.params)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setpermissionexport() {
      if (
        this.dataapp.features.every(x => x.name != 'User-List-Redeem-Export')
      ) {
        return (this.setperrmissionexport = false)
      }
    },
    postExpireCampaign(record) {
      this.dataRecord = record
      this.modalExpire = !this.modalExpire
    },
    handleCancel() {
      this.expirydate = null
      this.modalExpire = false
    },
    PostponeExpire() {
      this.modalExpire = false
      var dataExpire = {
        appid: ConfigManager.getAppId(),
        campaign_id: this.dataRecord.campaign_id,
        serial: this.dataRecord.serial,
        user_id: this.$route.params.id,
        postpone_date: this.expirydate,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostponeExpire(dataExpire)
          .then(res => {
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.applyFilter()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';

.page-userdetail {
  .campaign_type {
    width: 160px;
    float: left;
    margin-left: 10px;
  }
}

.select-search {
  width: 150px !important;
}

.iswinner {
  font-size: 12px;

  .not-yet {
    color: #666666;
  }

  .winner {
    color: #2ec743;
  }

  .not-winner {
    color: #fba45b;
  }
}
</style>
<style lang="scss">
.redeem {
  .ant-table-row {
    vertical-align: top !important;
  }
}
</style>
