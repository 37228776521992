<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-adjust bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex align-items-center">
              <h4 class="pr-2">{{ dataApproveByID.user_id }}</h4>
              <!-- <span v-if="dataApproveByID.name_of_user"> : </span>
              <span v-if="dataApproveByID.name_of_user" class="text-id pr-2">{{
                dataApproveByID.name_of_user
              }}</span> -->
              <div v-for="(item, index) in status" :key="`index-${index}`">
                <a-tag
                  v-if="dataApproveByID.status == item.name"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge
                    :status="item.color"
                    :text="textStatus(dataApproveByID.status)"
                  />
                </a-tag>
              </div>
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="bg-con">
              <div class="d-flex">
                <div class="mr-2">Create Date :</div>
                <div>
                  {{
                    formatDate(
                      dataApproveByID.created_date,
                      'DD/MMM/YYYY',
                      this.isTimezone * -1,
                    )
                  }}
                </div>
              </div>
              <div class="d-flex mt-1">
                <div class="mr-2">Created By :</div>
                <div>{{ dataApproveByID.auditable.name }}</div>
              </div>
              <hr class="my-2" />
              <a-row>
                <a-col :span="12">
                  <div class="d-flex">
                    <div class="mr-2">Send Notification :</div>
                    <template v-for="(item, index) in status_noti">
                      <a-tag
                        v-if="
                          dataApproveByID.notification.send_notification ==
                          item.name
                        "
                        :key="index"
                        :color="item.color == 'default' ? null : item.color"
                        class="tag-status"
                      >
                        <a-badge
                          v-if="
                            dataApproveByID.notification.send_notification ==
                            item.name
                          "
                          :status="item.color"
                          :text="item.text"
                        />
                      </a-tag>
                    </template>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="d-flex">
                    <div class="mr-2">Channel :</div>
                    <div class="status">
                      <a-tag color="purple" class="tag-status">
                        <a-badge status="purple" text="App Notification" />
                      </a-tag>
                    </div>
                  </div>
                </a-col>
                <a-col :span="24" class="mt-1">
                  <div class="d-flex">
                    <div class="mr-2">Notification Message :</div>
                    <div>
                      {{
                        dataApproveByID.notification.message
                          ? dataApproveByID.notification.message
                          : '-'
                      }}
                    </div>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="bg-con">
              <div class="d-flex">
                <div class="mr-2">User :</div>
                <div>
                  {{ dataApproveByID.user_id }} -
                  {{ dataApproveByID.name_of_user }}
                </div>
              </div>
              <div class="d-flex mt-1">
                <div class="mr-2">Adjust Points :</div>
                <div class="point-plus" v-if="dataApproveByID.points > 0">
                  +{{ dataApproveByID.points.toLocaleString('en-US') }}
                </div>
                <div class="point-minus" v-else>
                  {{ dataApproveByID.points.toLocaleString('en-US') }}
                </div>
              </div>
              <div class="d-flex mt-1">
                <div class="mr-2">Reason :</div>
                <div>{{ dataApproveByID.reason || '-' }}</div>
              </div>
              <div class="d-flex mt-1">
                <div class="mr-2">Additional Comment :</div>
                <div>{{ dataApproveByID.additional_comment || '-' }}</div>
              </div>
            </div>
          </div>
          <div
            v-if="
              dataApproveByID.status == 'Create' &&
              (managepointpermission ||
                rejectpermission ||
                approvepointpermission)
            "
            class="p-3"
          >
            <div class="mb-2">Remark :</div>
            <a-textarea
              v-model:value="remark"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
            <div v-if="requireRemark && !remark" class="mt-1 text-danger">
              * Please enter a reason.
            </div>
          </div>
          <div
            v-if="
              dataApproveByID.status == 'L1Approved' ||
              dataApproveByID.status == 'Reject'
            "
          >
            <div class="bg-con">
              <a-row>
                <a-col :span="6">
                  <div>Remark :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataApproveByID.approve_reject_reason
                        ? dataApproveByID.approve_reject_reason
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div>Responded date :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataApproveByID.approve_reject_date
                        ? formatDate(
                            dataApproveByID.approve_reject_date,
                            'DD/MMM/YYYY',
                            this.isTimezone * -1,
                          )
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div>Respondent :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataApproveByID.approve_reject_by
                        ? dataApproveByID.approve_reject_by.name
                        : '-'
                    }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
          <div
            :class="
              dataApproveByID.status != 'Create'
                ? 'px-4 py-3'
                : 'flex justify-between px-4 py-3'
            "
          >
            <div
              class=""
              v-if="
                managepointpermission ||
                rejectpermission ||
                approvepointpermission
              "
            >
              <a-button
                type="danger"
                v-if="
                  dataApproveByID.status == 'Create' &&
                  (rejectpermission || managepointpermission)
                "
                class="btn-create mr-3"
                @click="RejectPoint()"
                >Reject</a-button
              >
            </div>
            <div
              :class="
                dataApproveByID.status != 'Create' || !managepointpermission
                  ? 'flex-auto'
                  : 'flex'
              "
            >
              <div>
                <a-button
                  type="primary-2"
                  :class="
                    dataApproveByID.status != 'Create' || !managepointpermission
                      ? 'w-maxmax btn-create'
                      : 'mr-3 btn-create'
                  "
                  @click="dismiss(true)"
                >
                  Close
                </a-button>
              </div>
              <div
                v-if="
                  managepointpermission ||
                  rejectpermission ||
                  approvepointpermission
                "
              >
                <a-button
                  v-if="
                    dataApproveByID.status == 'Create' &&
                    (approvepointpermission || managepointpermission)
                  "
                  type="success"
                  class="btn-create"
                  @click="ApprovePoint()"
                >
                  Approve
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import Util from '@/helper/Utility'
// import moment from 'moment'
// import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import CacheHelper from '@/helper/CacheHelper.js'
// var Cache = new CacheHelper(ConfigManager.getCacheType())
import { config } from '@/config/bzbsconfig.js'
// import _ from 'lodash'
export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataApproveByID: {
      type: Array,
    },
    approvepointpermission: {
      type: Boolean,
    },
    rejectpermission: {
      type: Boolean,
    },
    managepointpermission: {
      type: Boolean,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['getapprovelist'],
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      newdata: {},
      drag: false,
      requireRemark: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      remark: '',
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      levelname: '',
      description: '',
      earnpoint: '',
      benefits: '',
      isErrorName: false,
      status_noti: [
        {
          id: 1,
          name: false,
          text: 'No',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Yes',
          color: 'green',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'L1Approved',
          color: 'green',
        },
      ],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {},
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Waiting'
        case 'L1Approved':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    onChange() {
      this.setdisable = false
      if (this.levelname) {
        this.isErrorName = false
      }
    },
    ApprovePoint() {
      this.$store.commit('setLoading', true)
      this.PostApprovePoint().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.remark = ''
            this.$emit('getapprovelist')
          },
        })
      })
    },
    PostApprovePoint() {
      var approvepoint = {
        appid: this.dataApproveByID.app_id,
        user_id: this.dataApproveByID.user_id,
        reference_id: this.dataApproveByID.reference_id,
        reason: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApprovePoint(approvepoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    RejectPoint() {
      if (!this.remark) {
        this.requireRemark = true
      } else {
        this.requireRemark = false
        this.$store.commit('setLoading', true)
        this.PostRejectPoint().then(() => {
          this.$store.commit('setLoading', false)
          this.$success({
            title: 'Success',
            okType: 'default',
            centered: true,
            onOk: () => {
              this.remark = ''
              this.$emit('getapprovelist')
            },
          })
        })
      }
    },
    PostRejectPoint() {
      var rejectpoint = {
        appid: this.dataApproveByID.app_id,
        user_id: this.dataApproveByID.user_id,
        reference_id: this.dataApproveByID.reference_id,
        reason: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectPoint(rejectpoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },

    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function () {
      this.levelname = ''
      this.description = ''
      this.earnpoint = ''
      this.benefits = ''
      this.remark = ''
      this.requireRemark = false
      this.$emit('handleClose')
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
  .input-error {
    border-color: #ff5252 !important;
  }
  .text-error {
    color: #ff5252;
  }
}
.w-maxmax {
  width: -webkit-fill-available !important;
}
.pointer {
  cursor: pointer;
}
.w-log-adjust {
  width: 40rem;
  .ml-60 {
    margin-left: 60px;
  }
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}
</style>
