<template>
  <AppLayout :disable-menu="false" :menu-callcenter="true">
    <div class="d-flex justify-between">
      <TitleBreadcrumbs class="ml-5" title="Job List" />
    </div>
    <div class="joblist px-4">
      <div class="mb-4 b-6 bg-white pl-4 pr-4 d-flex">
        <div class="py-2 d-flex gap-3">
          <a-select
            class="select-app"
            v-model:value="application"
            allow-clear
            placeholder="Select application"
            :options="application_option"
            show-search
            @change="selectApp(application)"
          />
          <a-select
            class="select-search"
            v-model:value="status"
            placeholder="All status"
            :options="options_status"
            :allowClear="true"
          ></a-select>
          <div class="wrap-date-filter">
            <a-button class="btn-date-filter" @click="toggleDateFilter">
              {{ dateFilterValue }}
              <calendar-outlined />
            </a-button>
            <DateFilter
              v-model:value="showDateFilter"
              :hasCalendar="true"
              @selectRange="selectRange($event)"
              @selectCalendar="selectCalendar($event)"
              @reset="resetDatefilter($event)"
              @handleCloseDaterange="showDateFilter = false"
            />
          </div>
          <a-select
            v-if="application && application != 'All'"
            class="select-search"
            allow-clear
            v-model:value="channel"
            placeholder="Select channel"
            :options="options_channel"
          />
          <a-select
            v-if="application && application != 'All'"
            class="select-search"
            v-model:value="subject"
            allow-clear
            placeholder="Select Subject"
            :options="options_subject"
          />
          <a-button type="primary" @click="clicksearch()" class="btn-search"
            >Search</a-button
          >
          <div class="border-l d-flex">
            <a-tooltip placement="bottom">
              <template #title>
                <div>Advance Search</div>
              </template>
              <a-button
                class="hover btn-icon"
                @click="toggleAdvanceSearch()"
                :color="'blue-1'"
              >
                <img class="left" src="@/assets/icons/FileSearch.svg" alt />
              </a-button>
            </a-tooltip>
          </div>
          <a-button
            v-if="checkPermissionExport()"
            type="teriary"
            @click="exportReport()"
          >
            <file-excel-outlined /> Export All
          </a-button>
        </div>
      </div>
      <div class="wrap-table" @scroll="handleScroll">
        <!-- <VLoadingTable :value="overlaytable" /> -->
        <a-table
          row-key="partitionkey"
          class="fixed-head"
          :dataSource="datajoblist"
          :columns="columns"
          :pagination="false"
        >
          <template #id="{ record }">
            <div v-if="checkPermission(record.app_id)">
              <a
                class="text-link"
                @click="toggleTicketDetail(record, checkAppName(record.app_id))"
                >{{ record.job_id ? record.job_id : '-' }}</a
              >
            </div>
            <div v-else>{{ record.job_id ? record.job_id : '-' }}</div>
          </template>
          <template #application="{ record }">
            {{ checkAppName(record.app_id) }}
          </template>
          <template #agency="{ record }">
            {{ record.agency_name ? record.agency_name : '-' }}
          </template>
          <template #subject="{ record }">
            {{ record.subject ? record.subject : '-' }}
          </template>
          <template #topic_type="{ record }">
            {{ record.topic_type ? record.topic_type : '-' }}
          </template>
          <template #topic_detail="{ record }">
            {{ record.topic_detail ? record.topic_detail : '-' }}
          </template>
          <template #user_id="{ record }">
            {{ record.user_id ? record.user_id : '-' }}
          </template>
          <template #priority="{ record }">
            <template v-for="(item, index) in statuspriority">
              <a-tag
                v-if="record.priority == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge :status="item.color" :text="record.priority" />
              </a-tag>
            </template>
          </template>
          <template #created_by="{ record }">
            {{ record.create_by ? record.create_by : '-' }}
          </template>
          <template #status="{ record }">
            <template v-for="(item, index) in statusprocess">
              <a-tag
                v-if="record.ticket_status == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge :status="item.color" :text="record.ticket_status" />
              </a-tag>
            </template>
          </template>
          <template #created_date="{ record }">
            {{
              record.create_date
                ? formatDate(record.create_date, 'DD/MMM/YYYY - HH:mm')
                : '-'
            }}
          </template>
          <template #last_updated="{ record }">
            {{
              record.last_update
                ? formatDate(record.last_update, 'DD/MMM/YYYY - HH:mm')
                : '-'
            }}
          </template>
          <template #channel="{ record }">
            {{ record.channel_name ? record.channel_name : '-' }}
          </template>
        </a-table>
      </div>
    </div>
  </AppLayout>
  <ExportModal
    :visible="showExportModal"
    @handleSubmit="handleExportModal($event)"
    @handleClose="showExportModal = false"
  ></ExportModal>
  <PrepareModal
    :visible="showPrepareModal"
    @handleClose="showPrepareModal = false"
  ></PrepareModal>
  <DisplayFieldsAdvanceSearch
    ref="advancesearch"
    :value="showModalAdvanceSearch"
    :application_option="application_option_noall"
    @searchAdvance="searchAdvance($event)"
    @handleClose="showModalAdvanceSearch = false"
  >
  </DisplayFieldsAdvanceSearch>
  <DisplayFieldsTicketDetail
    ref="jobDetail"
    :value="showModalTicketDetail"
    :dataDetailAll="dataDetailAll"
    :dataUserInfo="dataUserInfo"
    :dataDetail="dataDetail"
    :appName="appName"
    @clicksearch="clicksearch($event)"
    @handleClose="showModalTicketDetail = false"
  >
  </DisplayFieldsTicketDetail>
</template>

<script>
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
import { config } from '@/config/bzbsconfig.js'
import DisplayFieldsAdvanceSearch from '@/module/CallCenter/component/DisplayFieldsAdvanceSearchJoblist.vue'
import DisplayFieldsTicketDetail from '@/module/CallCenter/component/DisplayFieldsTicketDetail.vue'
import DateFilter from '@/module/User/component/DateFilter.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import PrepareModal from '@/module/CallCenter/component/PrepareModal.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    // TopBar,
    // Footer,
    DisplayFieldsAdvanceSearch,
    DisplayFieldsTicketDetail,
    // SidebarMenu,
    DateFilter,
    PrepareModal,
    ExportModal,
    AppLayout,
    TitleBreadcrumbs,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      showExportModal: false,
      showPrepareModal: false,
      breadcrumbs: [
        {
          name: 'select_application',
          breadcrumbName: 'Select Applications',
        },
        {
          name: null,
          breadcrumbName: 'Job List',
        },
      ],
      startdate: null,
      enddate: null,
      showDateFilter: false,
      dateFilterValue: 'Today',
      formatdate: 'DD/MMM/YYYY',
      datasetFeatures: JSON.parse(localStorage.getItem('setFeatures')),
      appId_list: JSON.parse(localStorage.getItem('applist')),
      permission: JSON.parse(
        localStorage.getItem('permissionappforcallcenter'),
      ),
      subject: null,
      sidebarHeaderconfig: { bgColor: '#fff' },
      sidebarMenu: [],
      sidebarOpen: true,
      overlay: true,
      applications: [],
      rawapp: [],
      mode: 'card',
      datajoblist: [],
      columns: [
        {
          title: 'Ticket ID',
          dataIndex: 'id',
          slots: { customRender: 'id' },
          key: 'id',
          width: 110,
          align: 'left',
        },
        {
          title: 'Application',
          dataIndex: 'application',
          slots: { customRender: 'application' },
          key: 'application',
          width: 220,
          align: 'left',
        },
        {
          title: 'Agency',
          dataIndex: 'agency',
          slots: { customRender: 'agency' },
          key: 'agency',
          width: 220,
          align: 'left',
        },
        {
          title: 'Subject',
          dataIndex: 'subject',
          slots: { customRender: 'subject' },
          key: 'subject',
          width: 220,
          align: 'left',
        },
        {
          title: 'Topic type',
          dataIndex: 'topic_type',
          slots: { customRender: 'topic_type' },
          key: 'topic_type',
          width: 220,
          align: 'left',
        },
        {
          title: 'Topic detail',
          dataIndex: 'topic_detail',
          slots: { customRender: 'topic_detail' },
          key: 'topic_detail',
          width: 220,
          align: 'left',
        },
        {
          title: 'User ID',
          dataIndex: 'user_id',
          slots: { customRender: 'user_id' },
          key: 'user_id',
          width: 120,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 140,
          align: 'left',
        },
        {
          title: 'Priority',
          dataIndex: 'priority',
          slots: { customRender: 'priority' },
          key: 'priority',
          width: 140,
          align: 'left',
        },
        {
          title: 'Channel',
          dataIndex: 'channel',
          slots: { customRender: 'channel' },
          key: 'channel',
          width: 160,
          align: 'left',
        },
        {
          title: 'Created Date',
          dataIndex: 'create_date',
          slots: { customRender: 'created_date' },
          key: 'create_date',
          width: 180,
          align: 'left',
        },
        {
          title: 'Created By',
          dataIndex: 'created_by',
          slots: { customRender: 'created_by' },
          key: 'created_by',
          width: 120,
          align: 'left',
        },
        {
          title: 'Last Updated',
          dataIndex: 'last_updated',
          slots: { customRender: 'last_updated' },
          key: 'last_updated',
          width: 180,
          align: 'left',
        },
      ],
      showModalAdvanceSearch: false,
      showModalTicketDetail: false,
      fromDataJoblist: {},
      application_option: [],
      application: 'All',
      options_channel: [],
      dataChannel: [],
      options_subject: [],
      dataSubject: [],
      channel: null,
      statuspriority: [
        {
          id: 1,
          name: 'Medium',
          text: 'Medium',
          color: 'purple',
        },
        {
          id: 2,
          name: 'Low',
          text: 'Low',
          color: 'green',
        },
        {
          id: 3,
          name: 'High',
          text: 'High',
          color: 'pink',
        },
        {
          id: 4,
          name: 'Critical',
          text: 'Critical',
          color: 'red',
        },
      ],
      statusprocess: [
        {
          id: 1,
          name: 'Open',
          text: 'Open',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Pending',
          text: 'Pending',
          color: 'yellow',
        },
        {
          id: 3,
          name: 'Cancel',
          text: 'Cancel',
          color: 'red',
        },
        {
          id: 4,
          name: 'Close',
          text: 'Close',
          color: 'default',
        },
      ],
      status: null,
      options_status: [
        {
          label: 'All Status',
          value: null,
        },
        {
          label: 'Open',
          value: 'Open',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
        {
          label: 'Cancel',
          value: 'Cancel',
        },
        {
          label: 'Close',
          value: 'Close',
        },
      ],
      page: 1,
      dataDetail: {},
      dataDetailAll: {},
      dataUserInfo: {},
      appName: '',
      isLoadmore: false,
      application_option_noall: [],
      advanceSearch: {},
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'My Applications',
        },
      ]
    },
  },
  mounted() {},
  created() {
    this.$store.commit('setLoading', true)
    this.startdate = moment().format('YYYY-MM-DD')
    this.enddate = moment().format('YYYY-MM-DD')
    this.dateFilterValue = 'Today'
    this.clicksearchs()
    this.application_option = this.appId_list.map(item => {
      return {
        label: item.appName,
        value: `${item.appId}|${item.appName}`,
      }
    })
    this.application_option_noall = this.appId_list.map(item => {
      return {
        label: item.appName,
        value: `${item.appId}|${item.appName}`,
      }
    })
    this.application_option.unshift({
      label: 'All Application',
      value: 'All',
    })
    this.$store.commit('setLoading', false)
  },
  methods: {
    searchAdvance(search) {
      this.getChannel(search.application.split('|')[0]).then(() => {
        if (search.channel) {
          var channel = this.options_channel.filter(
            x => x.value == search.channel,
          )
          this.channel = channel
        } else {
          this.channel = null
        }
      })
      this.getSubject(search.application.split('|')[0])
      this.advanceSearch = search
      this.status = search.status
      this.application = search.application.split('|')[0]
      this.subject = search.subject
      if (search.created_date) {
        this.startdate = search.created_date
        this.enddate = search.created_date
        this.dateFilterValue =
          dayjs(this.startdate).format(this.formatdate) +
          ' to ' +
          dayjs(this.enddate).format(this.formatdate)
      } else {
        this.dateFilterValue = 'Date Range'
      }
      var fromDataJoblist = {
        appId: search.application.split('|')[0],
        // ? [search.application.split('|')[0]]
        // : this.appId_list.map(x => x.appId),
        fromdate: search.created_date
          ? dayjs(search.created_date).format('DD/MMM/YYYY')
          : null,
        enddate: search.created_date
          ? dayjs(search.created_date).format('DD/MMM/YYYY')
          : null,
        channel: search.channel ? search.channel : null,
        subject: search.subject ? search.subject : null,
        topic_type: search.topic_type ? search.topic_type : null,
        ticket_iD: search.ticket_id ? search.ticket_id : null,
        created_by: search.created_by ? search.created_by : null,
        priority: search.priority ? search.priority : null,
        status: search.status ? search.status : null,
        page_number: this.page ? this.page : null,
        limit: 20,
      }
      this.getJobList('advancesearch', fromDataJoblist)
      this.showModalAdvanceSearch = false
    },
    goToDownload() {
      this.$router.push({
        name: 'downloadcenter-callcenter',
      })
    },
    checkPermissionDownload() {
      if (
        this.permission.find(x =>
          x.features.find(y => y.name == 'Job-List-Download-View'),
        )
      ) {
        return true
      } else {
        return false
      }
    },
    checkPermissionExport() {
      if (
        this.permission.find(x =>
          x.features.find(y => y.name == 'Job-List-Export'),
        )
      ) {
        return true
      } else {
        return false
      }
    },
    checkPermission(appid) {
      if (appid) {
        var check = this.permission.find(x => x.application_id == appid)
        if (check.features.find(y => y.name == 'Job-Detail-View')) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    checkAppName(appid) {
      if (appid) {
        var app = this.appId_list.find(x => x.appId == appid)
        return app.appName
      } else {
        return '-'
      }
    },
    clicksearchs() {
      this.isLoadmore = false
      this.showDateFilter = false
      this.page = 1
      this.datajoblist = []
      this.getJobList('search').then(() => {
        this.isLoadmore = true
      })
    },
    clicksearch() {
      this.$refs.advancesearch.clearSearch()
      this.isLoadmore = false
      this.showDateFilter = false
      this.page = 1
      this.datajoblist = []
      this.getJobList('search').then(() => {
        this.isLoadmore = true
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable && this.isLoadmore) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getJobList('search').then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getJobList(search, fromDataJoblist) {
      this.$store.commit('setLoading', true)
      var fromData = {}
      if (search == 'search') {
        this.fromDataJoblist = {
          appId: this.application.split('|')[0],
          status: this.status ? this.status : null,
          fromdate: this.startdate ? this.startdate : null,
          enddate: this.enddate ? this.enddate : null,
          channel: this.channel ? this.channel : null,
          subject: this.subject ? this.subject : null,
          created_by: this.created_by ? this.created_by : null,
          page_number: this.page ? this.page : null,
          limit: 20,
        }
        fromData = this.fromDataJoblist
      } else {
        fromData = fromDataJoblist
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostMasterJoblist(fromData)
          .then(res => {
            if (this.page == 1) {
              this.datajoblist = []
            }
            _.forEach(res.data.result, item => {
              this.datajoblist.push(item)
            })
            if (res.data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    getJoblistDetail(appid, id) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetJoblistDetail(appid, id).subscribe(
          res => {
            this.dataDetailAll = res.result
            this.$refs.jobDetail.setDataDetail(this.dataDetailAll, appid)
            this.$store.commit('setLoading', false)
            resolve(this.dataDetailAll)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    selectApp(app) {
      this.subject = null
      this.channel = null
      this.options_subject = []
      this.options_channel = []
      if (app && app != 'All') {
        this.getChannel(app)
        this.getSubject(app)
      }
    },
    getSubject(appid) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubjectForCreateTicket(
          appid.split('|')[0],
          true,
        ).subscribe(
          res => {
            this.dataSubject = res.result
            this.options_subject = []
            this.options_subject = this.dataSubject.items
              .filter(x => x.parent_problem_type_id == 0 && x.is_show)
              .map((item, index) => {
                return {
                  label: item.type,
                  value: item.type,
                  key: index,
                  id: item.id,
                }
              })
            resolve(this.dataSubject)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getChannel(appid) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetChannelJoblist(appid.split('|')[0]).subscribe(
          res => {
            this.dataChannel = res.result
            this.options_channel = []
            this.options_channel = this.dataChannel.items.map((item, index) => {
              return {
                label: item.name,
                value: item.id,
                key: index,
              }
            })

            resolve(this.dataChannel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getprofile(user_id, appid) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserProfile(user_id, appid).subscribe(
          res => {
            this.dataUserInfo = res.result
            resolve(this.profile)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleAdvanceSearch() {
      this.showModalAdvanceSearch = !this.showModalAdvanceSearch
    },
    toggleTicketDetail(record, appName) {
      this.dataDetail = record
      this.appName = appName
      if (record.user_id) {
        this.getprofile(record.user_id, record.app_id).then(() => {
          this.getJoblistDetail(record.app_id, record.job_id)
          this.showModalTicketDetail = !this.showModalTicketDetail
        })
      } else {
        this.getJoblistDetail(record.app_id, record.job_id).then(() => {
          this.showModalTicketDetail = !this.showModalTicketDetail
        })
      }
    },
    goToManageSubject() {
      this.$router.push({
        name: 'ticketmanagement',
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    exportReport() {
      this.showExportModal = true
    },
    handleExportModal(value) {
      var params = {}
      if (Object.keys(this.advanceSearch).length > 0) {
        var paramsadvance = {
          name: value.filename,
          description: value.description,
          filter: {
            appid: this.advanceSearch.application.split('|')[0],
            appid_list: [this.advanceSearch.application.split('|')[0]],
            fromdate: this.advanceSearch.created_date
              ? dayjs(this.advanceSearch.created_date).format('YYYY-MM-DD')
              : null,
            enddate: this.advanceSearch.created_date
              ? moment(this.advanceSearch.created_date)
                  .endOf('day')
                  .add(7, 'hours')
              : null,
            priority: this.advanceSearch.priority
              ? this.advanceSearch.priority
              : null,
            channel: this.advanceSearch.channel
              ? this.advanceSearch.channel
              : null,
            ticket_iD: this.advanceSearch.ticket_id
              ? this.advanceSearch.ticket_id
              : null,
            status: this.advanceSearch.status
              ? this.advanceSearch.status
              : null,
            created_by: this.advanceSearch.created_by
              ? this.advanceSearch.created_by
              : null,
            subject: this.advanceSearch.subject
              ? this.advanceSearch.subject
              : null,
            topic_type: this.advanceSearch.topic_type
              ? this.advanceSearch.topic_type
              : null,
          },
        }
        params = paramsadvance
      } else {
        var paramssearch = {
          name: value.filename,
          description: value.description,
          filter: {
            appid:
              this.application != 'All'
                ? this.application.split('|')[0]
                : this.appId_list[0].appId,
            appid_list:
              this.application && this.application != 'All'
                ? [this.application.split('|')[0]]
                : this.appId_list.map(x => x.appId),
            fromdate: this.startdate
              ? moment(this.startdate).format('YYYY-MM-DD')
              : null,
            enddate: this.enddate
              ? moment(this.enddate).endOf('day').add(7, 'hours')
              : null,
            status: this.status ? this.status : null,
            channel: this.channel ? this.channel : null,
            topic_type: this.subject ? this.subject : null,
          },
        }
        params = paramssearch
      }
      this.callAPiExportJoblis(params).then(() => {
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    callAPiExportJoblis(params) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostJoblistExport(params)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.left-menu-width {
  // flex: 0 0 $left-menu-width;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 8%);
  width: $left-menu-width;
  z-index: 15;

  &.collapse {
    // width: 44px;
    width: 64px;
    // flex: 0 0 55px;
  }
}
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
  .box-select-app {
    padding: 0px 20px 20px 20px;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.joblist {
  .status-search {
    width: 150px;
    padding-left: 15px;
  }
  .datefilter {
    right: unset !important;
  }
  @media only screen and (max-width: 1800px) {
    .datefilter {
      left: -380px !important;
    }
  }
  .btn-date-filter {
    width: 260px;
    text-align: left;
    .anticon {
      float: right;
      margin-top: 2px;
    }
  }
  .download {
    position: absolute;
    top: 50px;
    right: 20px;
  }
  .select-search {
    width: 150px !important;
  }
  .wrap-date-filter {
    position: relative;
  }
  .datefilter {
    right: 0;
    left: unset;
  }
  .table-scroll-joblist {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    // box-shadow: 0 2px 4px 0 rgb(102 108 139 / 10%);
    min-height: calc(100vh - 244px);
    max-height: calc(100vh - 244px);
  }
  .wrap-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 244px);
    .ant-table-wrapper {
      min-width: 2380px !important;
      width: 100%;
    }
  }
  .managesub {
    position: absolute;
    right: 22px;
    top: 132px;
  }
}
</style>
