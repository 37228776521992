<template>
  <div class="ant-layout-sider-group">
    <div class="logo">
      <a>
        <img :src="img_logo" />
      </a>
    </div>
    <div
      class="ant-layout-sider-overlay"
      :class="{ overlayed: !collapsed }"
      @click="collapsed = !collapsed"
    ></div>
    <a-layout-sider v-if="!collapsed" width="70" class="sider-mainmenu">
      <a-menu
        mode="inline"
        :theme="$store.state.themecolor"
        :selected-keys="['User-Management']"
      >
        <template v-for="product in menuproduct">
          <a-menu-item
            v-if="product.is_visible"
            :key="product.name"
            :title="product.display_name"
            :disabled="product.name == 'User-Management'"
          >
            <a @click="gotoOtherWeb(product)">
              <!-- <i class="anticon">
                <i :class="product.icon"></i>
              </i> -->
              <img
                :src="
                  blob +
                  '/themebackoffice/assets/img/menu/' +
                  product.icon +
                  '.normal.svg'
                "
                class="normal"
              />
              <img
                :src="
                  blob +
                  '/themebackoffice/assets/img/menu/' +
                  product.icon +
                  '.active.svg'
                "
                class="active"
              />
              <img
                :src="
                  blob +
                  '/themebackoffice/assets/img/menu/' +
                  product.icon +
                  '.disable.svg'
                "
                class="disabled"
              />
            </a>
          </a-menu-item>
        </template>
      </a-menu>
      <a-dropdown
        placement="topRight"
        class="dropdown-helpcenter"
        overlayClassName="overlay-helpcenter"
        :getPopupContainer="trigger => trigger.parentNode"
      >
        <a v-if="helpCenterProduct" class="dropdownmenu" @click.prevent>
          <question-circle-outlined :style="{ color: 'white' }" />
        </a>
        <template #overlay>
          <a-menu>
            <template v-if="helpCenterProduct.menus.length > 0">
              <template v-for="sub in helpCenterProduct.menus">
                <a-menu-item
                  v-if="sub.page"
                  :key="sub.page.route_name"
                  @click="gotoHelpCenter(sub.page.route_name)"
                >
                  {{ sub.page.display_name }}
                </a-menu-item>
              </template>
              <a-menu-divider />
            </template>
            <a-menu-item>
              <span @click="isModalSLA = true"> Service Level Agreement </span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-layout-sider>
    <a-layout-sider
      v-model:collapsed="collapsed"
      collapsible
      breakpoint="xl"
      width="280"
      :trigger="null"
      :collapsed-width="70"
      class="sider-thismenu"
      :class="{ collapsed: collapsed }"
    >
      <div v-if="!menuCallcenter" class="box-scoll-menu">
        <div class="row-app">
          <div class="app_logo">
            <router-link :to="{ name: 'select_application' }">
              <img :src="logobyapp" @error="imageLoadError" />
            </router-link>
          </div>
          <div v-if="!collapsed" class="app_name">
            <p class="text-headline-3">{{ appname }}</p>
            <a @click="selectApp()"> Select application </a>
          </div>
        </div>
        <a-menu
          :theme="$store.state.themecolor"
          v-model:selected-keys="current"
          v-model:openKeys="openKeys"
          mode="inline"
        >
          <template
            v-for="item in menu?.filter(
              x => x.name != 'Call Center Management',
            )"
          >
            <a-menu-item v-if="item.path" :key="item.path" class="menu-item">
              <router-link :to="{ path: item.path }" :title="item.name">
                <span v-if="item.name == 'Download Center'" class="icon">
                  <download-outlined />
                </span>
                <span v-if="item.name == 'Help Chat'" class="icon">
                  <customer-service-outlined />
                </span>
                <span v-if="item.name == 'Import'" class="icon">
                  <import-outlined />
                </span>
                <span v-if="item.name == 'Search Serial'" class="icon">
                  <search-outlined />
                </span>
                <span v-if="!collapsed">
                  {{ item.name }}
                </span>
              </router-link>
            </a-menu-item>
            <a-sub-menu
              v-if="item.sub_menu.length > 0"
              :key="item.sequence"
              @titleClick="onOpenChange"
            >
              <template #title>
                <span v-if="item.name == 'User Management'" class="icon">
                  <unordered-list-outlined />
                </span>
                <span v-if="item.name == 'Setting'" class="icon">
                  <setting-outlined />
                </span>
                <span v-if="item.name == 'Call Center Setting'" class="icon">
                  <phone-outlined />
                </span>
                <span v-if="!collapsed">
                  {{ item.name }}
                </span>
              </template>
              <template v-for="subitem in filterSubmenu(item.sub_menu)">
                <a-menu-item v-if="subitem.path" :key="subitem.path">
                  <router-link
                    :title="subitem.name"
                    :to="{ path: subitem.path }"
                  >
                    {{ subitem.name }}
                  </router-link>
                </a-menu-item>
                <a-menu-item v-else :key="subitem.function">
                  <div
                    class="collapsed-hide coloraction"
                    @click="modalAction(subitem.function, true)"
                  >
                    <a href="javascript:void(0);" class="functionMenu">{{
                      subitem.name
                    }}</a>
                  </div>
                </a-menu-item>
              </template>
            </a-sub-menu>
          </template>
        </a-menu>
      </div>
      <div v-else class="box-scoll-menu">
        <a-menu
          :theme="$store.state.themecolor"
          v-model:selected-keys="current"
          v-model:openKeys="openKeys"
          mode="inline"
        >
          <template v-for="item in filterMeniPermission(menulistCallcenter)">
            <a-menu-item v-if="item.path" :key="item.path" class="menu-item">
              <router-link :to="{ path: item.path }" :title="item.name">
                <span v-if="item.name == 'Select Application'" class="icon">
                  <appstore-outlined />
                </span>
                <span v-if="item.name == 'Search User'" class="icon">
                  <search-outlined />
                </span>
                <span v-if="item.name == 'Search Serial EDC'" class="icon">
                  <file-search-outlined />
                </span>
                <span v-if="item.name == 'Job List'" class="icon">
                  <book-outlined />
                </span>
                <span v-if="item.name == 'Approve List'" class="icon">
                  <file-text-outlined />
                </span>
                <span v-if="item.name == 'Dowload Center'" class="icon">
                  <download-outlined />
                </span>
                <span v-if="item.name == 'FAQ'" class="icon">
                  <question-circle-outlined />
                </span>
                <span v-if="!collapsed">
                  {{ item.name }}
                </span>
              </router-link>
            </a-menu-item>
            <a-sub-menu
              v-if="item.sub_menu.length > 0"
              :key="item.sequence"
              @titleClick="onOpenChange"
            >
              <template #title>
                <span v-if="item.name == 'Setting'" class="icon">
                  <setting-outlined />
                </span>
                <span v-if="!collapsed">
                  {{ item.name }}
                </span>
              </template>
              <template v-for="subitem in filterSubmenu(item.sub_menu)">
                <a-menu-item v-if="subitem.path" :key="subitem.path">
                  <router-link
                    :title="subitem.name"
                    :to="{ path: subitem.path }"
                  >
                    {{ subitem.name }}
                  </router-link>
                </a-menu-item>
                <a-menu-item v-else :key="subitem.function">
                  <div
                    class="collapsed-hide coloraction"
                    @click="modalAction(subitem.function, true)"
                  >
                    <a href="javascript:void(0);" class="functionMenu">{{
                      subitem.name
                    }}</a>
                  </div>
                </a-menu-item>
              </template>
            </a-sub-menu>
          </template>
        </a-menu>
      </div>
      <div class="foot-trigger">
        <button
          class="trigger"
          :class="{ collapsed: collapsed }"
          @click="() => (collapsed = !collapsed)"
        >
          <menu-unfold-outlined v-if="collapsed" class="i-collapsed" />
          <menu-fold-outlined v-if="!collapsed" class="i-collapsed" />
        </button>
      </div>
    </a-layout-sider>
    <!-- SLA -->
    <ModalSLA
      v-if="isModalSLA"
      :is-show="isModalSLA"
      @closeModal="closeModalSLA()"
    />
  </div>
</template>
<script>
// import Cache from '@/helper/Cache.js'
import CommonMixin from '@/mixin/CommonMixin'
// import _ from 'lodash'
import Account from '@/helper/AccountHelper'
// import Config from '@/config/vueconfig.js'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
// import Config from '@/config/vueconfig.js'
import ModalSLA from '@/components/Layout/SiderNew/ModalSLA.vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import { config } from '@/config/bzbsconfig.js'
var cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'Sider',
  components: {
    ModalSLA,
  },
  mixins: [CommonMixin],
  props: {
    menuCallcenter: Boolean,
  },
  data() {
    return {
      menuproduct: JSON.parse(localStorage.getItem('setMenuProduct')),
      permissioncallcenter:
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket')),
      title: 'BUZZEBEES',
      logobyapp: ConfigManager.getAppLogo(),
      appname: ConfigManager.getAppName(),
      blob: config.bzbBlobUrl,
      img_logo:
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/logo-icon.svg',
      logo: require('@/assets/imgs/logo-icon.svg'),
      powerby: require('@/assets/imgs/powerby.png'),
      trigger: require('@/assets/icons/trigger.svg'),
      currentRoute: this.$router.currentRoute,
      openKeys: [],
      current: [],
      rootSubmenuKeys: [],
      menulistCallcenter: [
        {
          icon: 'setting-outlined',
          name: 'Select Application',
          path: '/select-applications',
          route_name: 'select-applications',
          permissionKey: 'Call-Center-Select-Application',
          sequence: 1,
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'Search User',
          path: '/searchuser',
          route_name: 'searchuser',
          permissionKey: 'Call-Center-Search-User',
          sequence: 1,
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'Search Serial EDC',
          path: '/searchserialcallcenter',
          route_name: 'searchserialcallcenter',
          permissionKey: 'Call-Center-Search-Serial-Campaign-View',
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'Job List',
          path: '/joblist',
          route_name: 'joblist',
          permissionKey: 'Job-List-View',
          sequence: 2,
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'Dowload Center',
          path: '/downloadcenter-callcenter',
          route_name: 'downloadcenter-callcenter',
          permissionKey: 'Job-List-Download-View',
          sequence: 3,
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'Approve List',
          path: '/approvelist-callcenter',
          route_name: 'approvelist-callcenter',
          permissionKey: 'Call-Center-Approve-List',
          sequence: 4,
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'FAQ',
          path: '/faq-callcenter',
          route_name: 'faq-callcenter',
          permissionKey: 'Call-Center-FAQ-View',
          sequence: 5,
          sub_menu: [],
        },
        {
          icon: 'setting-outlined',
          name: 'Setting',
          route_name: 'setting',
          permissionKey: 'Call-Center-FAQ-View',
          sequence: 5,
          sub_menu: [
            {
              icon: 'history',
              name: 'Subject',
              path: '/callcenter-setting/subject',
              route_name: 'callcenter-setting-subject',
              sequence: 1,
            },
            {
              icon: 'history',
              name: 'Channel',
              path: '/callcenter-setting/channel',
              route_name: 'callcenter-setting-channel',
              sequence: 2,
            },
          ],
        },
      ],
      menuList: [
        // {
        //   icon: 'campaign',
        //   name: 'User Management',
        //   route_name: 'usermanegement',
        //   permissionKey: 'User-List',
        //   sequence: 1,
        //   sub_menu: [
        //     {
        //       icon: 'history',
        //       name: 'User List',
        //       path: '/application/list',
        //       route_name: 'list',
        //       permissionKey: 'User-List',
        //       sequence: 2,
        //     },
        //     {
        //       icon: 'history',
        //       name: 'User List',
        //       path: '/application/listrealtime',
        //       route_name: 'listrealtime',
        //       permissionKey: 'User-List-Real-Time-Data',
        //       sequence: 2,
        //     },
        //     {
        //       icon: 'history',
        //       name: 'User Level',
        //       path: '/application/level',
        //       route_name: 'level',
        //       permissionKey: 'User-Level',
        //       sequence: 3,
        //     },
        //     {
        //       icon: 'history',
        //       name: 'User Tag',
        //       path: '/application/usertag',
        //       route_name: 'user_tag',
        //       permissionKey: 'User-Tag',
        //       sequence: 3,
        //     },
        //   ],
        // },
        // {
        //   icon: 'setting-outlined',
        //   name: 'Search Serial',
        //   path: '/application/searchserial',
        //   route_name: 'searchserial',
        //   permissionKey: 'Search-Serial-View',
        //   sequence: 4,
        //   sub_menu: [],
        // },
        // {
        //   icon: 'setting-outlined',
        //   name: 'Download Center',
        //   path: '/application/download',
        //   route_name: 'download',
        //   permissionKey: 'Download-Center-View',
        //   sequence: 5,
        //   sub_menu: [],
        // },
        // {
        //   icon: 'setting',
        //   name: 'Setting',
        //   route_name: 'setting',
        //   sequence: 5,
        //   sub_menu: [
        //     {
        //       icon: 'history',
        //       name: 'Approve List',
        //       path: '/application/approvepoint',
        //       route_name: 'approvepoint',
        //       permissionKey: 'Approve-Point-List',
        //       sequence: 6,
        //     },
        //     {
        //       icon: 'history',
        //       name: 'Config Database',
        //       path: '/application/configdatabase',
        //       route_name: 'configdatabase',
        //       permissionKey: 'Config-Storage-TableNameList',
        //       sequence: 7,
        //     },
        //   ],
        // },
        // {
        //   icon: 'campaign',
        //   name: 'Import',
        //   path: '/application/import',
        //   route_name: 'import',
        //   permissionKey: 'Import-List-Points',
        //   sequence: 8,
        //   sub_menu: [],
        // },
        // {
        //   icon: 'campaign',
        //   name: 'Help Chat',
        //   path: '/application/helpchat',
        //   route_name: 'helpchat',
        //   permissionKey: 'Help-Chat-List',
        //   sequence: 9,
        //   sub_menu: [],
        // },
        // {
        //   icon: 'campaign',
        //   name: 'Call Center Management',
        //   sequence: 10,
        //   sub_menu: [
        //     {
        //       icon: 'campaign',
        //       name: 'FAQ',
        //       path: '/application/faq',
        //       route_name: 'faq',
        //       permissionKey: 'FAQ-View',
        //       sequence: 6,
        //     },
        //     {
        //       icon: 'campaign',
        //       name: 'Ticket Management',
        //       path: '/application/ticketmanagement',
        //       route_name: 'ticketmanagement',
        //       permissionKey: 'FAQ-View',
        //       sequence: 6,
        //     },
        //   ],
        // },
        // {
        //   icon: 'campaign',
        //   name: 'Campaign Management',
        //   path: Config().CampaignManagementUrl + '/redirectms',
        //   route_name: 'campaignmanagement',
        //   sequence: 0,
        //   sub_menu: [],
        // },
      ],
      allproducts: [
        // {
        //   "icon": "campaign_management",
        //   "product_name": "Campaign_Management",
        //   "namename": "Campaign Management"
        // },
        // {
        //   "icon": "rule_based_engine",
        //   "product_name": "Rule-Based-Engine",
        //   "namename": "Rule Based Engine"
        // },
        // {
        //   "icon": "user_management",
        //   "product_name": "User-Management",
        //   "display_name": "User Management"
        // },
        // {
        //   "icon": "app_config",
        //   "product_name": "Package-Management",
        //   "display_name": "Package Management"
        // }
      ],
      setMenu: false,
      dataPermission: [],
      setperrmission: false,
      permissionbyapp: [],
      helpCenterProduct: null,
      isModalSLA: false,
      helpcenter: {},
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(newValue) {
        this.$store.commit('setCollapsed', newValue)
      },
    },
    user: {
      get() {
        return Account.getUserCache()
      },
    },
    menu: {
      get() {
        return this.menuList
      },
    },
    menupermission: {
      get() {
        return this.$store.state.menupermission
      },
    },
  },
  mounted() {
    if (!this.setMenu) {
      this.setActiveMenu()
    }
    setTimeout(() => {
      this.menuproduct = JSON.parse(localStorage.getItem('setMenuProduct'))
      this.permissioncallcenter =
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket'))
      if (this.permissioncallcenter) {
        this.filterMeniPermission(this.menulistCallcenter)
      }
    }, 200)
    console.log(JSON.parse(localStorage.getItem('setMenuProduct')))
  },
  created() {
    this.setMenu = false
    // this.setPermission( )
    this.handleLoading(false)
    this.menuList = JSON.parse(localStorage.getItem('setMenuPermissionNew'))
    this.helpCenterProduct = JSON.parse(
      localStorage.getItem('HelpcenterProduct'),
    )
    if (
      this.currentRoute.path != '/select-applications' &&
      this.currentRoute.path != '/callcentermanagement' &&
      this.currentRoute.path != '/ticketmanagement' &&
      this.currentRoute.path != '/downloadcenter-callcenter' &&
      this.currentRoute.path != '/searchuser' &&
      this.currentRoute.path != '/joblist' &&
      this.currentRoute.path != '/approvelist-callcenter' &&
      this.currentRoute.path != '/unauthorized' &&
      this.currentRoute.path != '/faq-callcenter' &&
      this.currentRoute.path != '/callcenter-setting/subject' &&
      this.currentRoute.path != '/searchserialcallcenter' &&
      this.currentRoute.path != '/callcenter-setting/channel'
    ) {
      this.menuList[0].sub_menu.splice(1, 0, {
        name: 'User List',
        route_name: 'listrealtime',
        sequence: 0,
        path: '/application/listrealtime',
      })
      this.permissionbyapp = JSON.parse(localStorage.getItem('permissionbyapp'))
    }
    this.setActiveMenu()
    // this.permissionbyapp = JSON.parse(localStorage.getItem('permissionbyapp')),
    // this.getAllProduct().then(result => {
    //   this.allproducts = result
    // })
  },
  methods: {
    imageLoadError(event) {
      event.target.src =
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/blank.png'
    },
    filterMeniPermission(menulistCallcenter) {
      return (
        menulistCallcenter.filter(f =>
          this.permissioncallcenter.find(x =>
            x.features.find(i => i.name.includes(f.permissionKey)),
          ),
        ) || []
      )
    },
    filterSubmenu(menu) {
      if (
        this.currentRoute.path != '/select-applications' &&
        this.currentRoute.path != '/callcentermanagement' &&
        this.currentRoute.path != '/unauthorized'
      ) {
        if (
          this.permissionbyapp.includes('User-List-View') &&
          this.permissionbyapp.includes('User-List-Real-Time-Data')
        ) {
          return menu.filter(z => z.route_name != 'listrealtime')
        } else if (
          !this.permissionbyapp.includes('User-List-View') &&
          this.permissionbyapp.includes('User-List-Real-Time-Data')
        ) {
          return menu.filter(z => z.route_name != 'list')
        } else if (
          this.permissionbyapp.includes('User-List-View') &&
          !this.permissionbyapp.includes('User-List-Real-Time-Data')
        ) {
          return menu.filter(z => z.route_name != 'listrealtime')
        }
        return menu
      } else {
        return menu
      }
    },
    setActiveMenu() {
      console.log(this.menu)
      if (this.menu) {
        this.menu.forEach(m => {
          if (m.sub_menu.length > 0) {
            m.sub_menu.forEach(sm => {
              if (this.currentRoute.path.includes(sm.path)) {
                console.log(this.current)
                console.log(sm.path)
                console.log(this.currentRoute.path)
                this.current = [sm.path]
                this.openKeys = [m.sequence]
              }
            })
          } else {
            if (this.currentRoute.path.includes(m.path)) {
              this.current = [m.path]
            }
          }
        })
      }
      if (this.menulistCallcenter) {
        this.menulistCallcenter.forEach(m => {
          if (this.currentRoute.path.includes(m.path)) {
            this.current = [m.path]
          }
        })
      }
    },
    // setPermission() {
    //   if (Cache.get('msPermission') == 'true') {
    //     this.menuList = this.menuList.filter(x => x.name != 'Bidding Agency')
    //   } else {
    //     this.menuList = this.menuList.filter(
    //       x => x.name != 'Bidding' && x.name != 'Setting',
    //     )
    //   }
    //   this.setActiveMenu()
    // },
    getAllProduct() {
      return new Promise(resolve => {
        var url =
          config.bzbBlobUrl +
          '/themebackoffice/assets/json/all_product.json?v=' +
          new Date().getTime()
        BzbsApi.connectBzbsGetBlob(url)
          .then(res => {
            resolve(res.data.allproducts)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    // setDisableMenu(product_name) {
    //   if (product_name != 'User-Management') {
    //     return true
    //   }
    // },
    checkSubMenu(submenu) {
      return submenu.filter(
        subitem => subitem.route_name == this.currentRoute.name.toLowerCase(),
      )
    },
    onOpenChange(openKeys) {
      if (!this.openKeys.includes(openKeys.key)) {
        this.openKeys = [openKeys.key]
      } else {
        this.openKeys = []
      }
    },
    // handleClick(e) {
    //   this.current = e.key
    // },
    selectApp() {
      cache.removeCache('app')
      cache.removeCache('setFilter')
      cache.removeCache('setFilterTotal')
      cache.removeCache('filterUser')
      cache.removeCache('handleSearch')
      cache.removeCache('SearchRealtime')
      cache.removeCache('totalBadge')
      this.$store.commit('setIsSearch', false)
      this.$router.push({
        name: 'select_application',
      })
    },
    gotoOtherWeb(menu) {
      if (menu.name == 'Product-Platform-External') {
        window.location = menu.url + '/redirectms'
      } else {
        if (cache.getCache('msUserData') && cache.getCache('app')) {
          window.location =
            menu.url +
            '/redirectms?application_id=' +
            encodeURIComponent(cache.getCache('app').id)
        } else {
          window.location = menu.url + '/redirectms'
        }
      }
    },
    // gotoOtherWeb(product_name) {
    //   if (product_name == 'User-Management') {
    //     window.location = Config().UsermanagementUrl + '/redirectms'
    //   }
    //   if (product_name == 'Rule-Based-Engine') {
    //     window.location = Config().bzbUrlRuleBasedEngine + '/redirectms'
    //   }
    //   if (product_name == 'Campaign-Management') {
    //     window.location = Config().CampaignManagementUrl + '/redirectms'
    //   }
    // },
    closeModalSLA() {
      this.isModalSLA = false
    },
    gotoHelpCenter(route_name) {
      window.location =
        this.helpCenterProduct.url +
        '/redirectms?target_page=' +
        encodeURIComponent(route_name)
    },
  },
}
</script>
<style lang="scss" scoped>
.font-pls {
  font-size: 12px;
  color: #777;
}

.i-collapsed {
  font-size: 24px;
}

.btn-select-app {
  color: #1890ff !important;
  text-decoration: underline;
  font-style: italic;
}
</style>
<style lang="scss">
.ant-menu-inline .ant-menu-item::after {
  border-color: #f9a601 !important;
}
.overlay-helpcenter.ant-dropdown {
  width: max-content !important;
}
</style>
