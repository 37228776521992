<template>
  <a-card class="w-full min-h-full">
    <nav class="filter-nav d-flex justify-content-between mb-3">
      <div class="d-flex gab-10">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            v-model:value="optionssearch"
            :options="options_search"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @keyup="enterFilter($event)"
            :allowClear="true"
          >
            <template #prefix>
              <a @click="applyFilter">
                <search-outlined />
              </a>
            </template>
            <template #suffix>
              <a @click="toggleAdvaceSearch">
                <img
                  class="ico-filter"
                  :class="{ active: checkActive }"
                  src="@/assets/icons/faders.svg"
                />
              </a>
              <FilterSearch
                ref="FilterSearch"
                :value="showAdvaceSearch"
                :FilterConfig="optionlist"
                @applyFilter="applyFilter($event, true)"
                @handleClose="showAdvaceSearch = false"
              />
            </template>
          </a-input>
        </a-input-group>
        <!-- <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
          />
        </div>
        <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch(true)"
            ><search-outlined /> Search</a-button
          >
        </div> -->
      </div>
      <div class="d-flex gab-10">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <a-button
          v-if="dataapp.includes('User-List-Coupon-Export')"
          type="teriary"
          @click="exportReport"
        >
          <file-excel-outlined /> Export as excel
        </a-button>
      </div>
    </nav>
    <div class="wrap-table" @scroll="handleScroll">
      <VLoadingTable :value="overlaytable" />
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataCoupon"
        :columns="displayFields"
        :pagination="false"
      >
      </a-table>
    </div>
    <DisplayFields
      ref="selectall"
      :value="showDisplayFields"
      :headers="columns"
      @apply="applyDisplayFields($event)"
      @handleClose="showDisplayFields = false"
    >
    </DisplayFields>
    <ExportModal
      :visible="showExportModal"
      @handleSubmit="handleExportModal($event)"
      @handleClose="showExportModal = false"
    ></ExportModal>
    <PrepareModal
      :visible="showPrepareModal"
      @handleClose="showPrepareModal = false"
    ></PrepareModal>
  </a-card>
</template>
<script>
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
// import { map } from "rxjs/operators";
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import FilterSearch from '@/components/Filter/FilterSearch.vue'
// import { CalendarOutlined } from '@ant-design/icons-vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import ConfigManager from '@/config/ConfigManager.js'

export default {
  name: 'UserCoupon',
  components: {
    DisplayFields,
    // DateFilter,
    // CalendarOutlined,
    ExportModal,
    PrepareModal,
    FilterSearch,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      drag: false,
      showDisplayFields: false,
      overlay: true,
      overlaytable: false,
      params: {
        name: '',
        filter: {
          app_id: '',
          user_id: '',
          coupon_code: '',
          product: '',
          from_date: null,
          to_date: null,
        },
      },
      optionssearch: 'product',
      myArray: [
        {
          name: 'test1',
          id: 1,
          fixed: false,
        },
        {
          name: 'test2',
          id: 2,
          fixed: true,
        },
        {
          name: 'test3',
          id: 3,
          fixed: false,
        },
      ],
      searchObject: {
        product: '',
        serialcode: '',
        startdate: '',
        enddate: '',
      },
      options_search: [
        {
          label: 'Products',
          value: 'product',
        },
        {
          label: 'Serial Code',
          value: 'serialcode',
        },
      ],
      optionlist: {
        status: false,
        daterange: true,
        options: [],
      },
      search: '',
      page: 1,
      columns: [
        {
          title: 'Coupon Date',
          dataIndex: 'entered_date',
          key: 'entered_date',
          width: 80,
          align: 'left',
        },
        {
          title: 'Serial Code',
          dataIndex: 'serial',
          key: 'serial',
          width: 80,
          align: 'left',
        },
        {
          title: 'Product Name',
          dataIndex: 'product',
          key: 'product',
          width: 80,
          align: 'left',
        },
        {
          title: 'Batch ID',
          dataIndex: 'batch_id',
          key: 'batch_id',
          width: 120,
          align: 'center',
        },
        {
          title: 'Points',
          dataIndex: 'product_point',
          key: 'product_point',
          width: 80,
          align: 'right',
        },
        {
          title: 'Special Points',
          dataIndex: 'special_points',
          key: 'special_points',
          width: 80,
          align: 'right',
        },
        {
          title: 'Total Points',
          dataIndex: 'total_point_received',
          key: 'total_point_received',
          width: 80,
          align: 'right',
        },
      ],
      dataCoupon: [],
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      startdate: null,
      enddate: null,
      formatdate: 'DD/MMM/YYYY',
      formattime: 'HH:mm:ss',
      showExportModal: false,
      showPrepareModal: false,
      datalastid: '1',
      checksearch: false,
      showAdvaceSearch: false,
      checkActive: false,
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'coupon') {
        this.displayFields = _.cloneDeep(this.columns)
        this.showAdvaceSearch = false
        this.checkActive = false
        this.$refs.FilterSearch.resetFilter()
        this.applyFilter()
        // this.callAPiReportCoupon().then(() => {
        //   this.$store.commit('setLoading', false)
        // })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'coupon') {
      this.displayFields = _.cloneDeep(this.columns)
      this.showAdvaceSearch = false
      this.overlay = false
      this.checkActive = false
      this.$refs.FilterSearch.resetFilter()
      this.applyFilter()
    }
  },
  created() {},
  methods: {
    enterFilter(key) {
      if (key.key == 'Enter' && this.showAdvaceSearch == false) {
        this.applyFilter()
      }
    },
    toggleAdvaceSearch() {
      this.showAdvaceSearch = !this.showAdvaceSearch
    },
    applyFilter(filter, checksearch) {
      if (filter?.startdate?.length > 0 && filter?.enddate?.length > 0) {
        this.checkActive = true
      } else {
        this.checkActive = false
      }
      if (checksearch) {
        this.datalastid = ''
      } else {
        if (this.datalastid == '1') {
          this.datalastid = ''
        }
      }
      this.$store.commit('setLoading', true)
      this.searchObject.product =
        this.optionssearch == 'product' ? this.search : ''
      this.searchObject.serialcode =
        this.optionssearch == 'serialcode' ? this.search : ''
      this.searchObject.startdate = filter?.startdate
        ? dayjs(filter?.startdate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.searchObject.enddate = filter?.enddate
        ? dayjs(filter?.enddate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.showDateFilter = false
      this.callAPiReportCoupon().then(() => {
        this.$store.commit('setLoading', false)
      })
      this.showAdvaceSearch = false
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          if (this.datalastid != '') {
            this.overlaytable = true
            this.callAPiReportCoupon().then(() => {
              this.overlaytable = false
            })
          }
        }
      }
    },
    callAPiReportCoupon() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserCouponHistory(
          this.$route.params.id,
          this.datalastid,
          this.searchObject.product,
          this.searchObject.serialcode,
          this.searchObject.startdate,
          this.searchObject.enddate,
        ).subscribe(
          data => {
            if (this.datalastid == '') {
              this.dataCoupon = []
            }
            this.datalastid = data.lastid
            _.forEach(data.result, item => {
              item.description = item.description ? item.description : '-'
              var time = item.entered_time.split(':')
              time.pop()
              item.entered_time = time.join(':')
              item.entered_date =
                moment(item.entered_date).format('DD/MMM/YYYY') +
                ' - ' +
                time.join(':')
              item.product_point = item.product_point.toLocaleString('en-US')
              item.special_points = item.special_points.toLocaleString('en-US')
              item.total_point_received =
                item.total_point_received.toLocaleString('en-US')
              this.dataCoupon.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataCoupon)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    exportReport() {
      this.showExportModal = true
    },
    handleExportModal(value) {
      var params = {
        filename: value.filename,
        description: value.description,
      }
      this.callAPiExportCoupon(params).then(() => {
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    callAPiExportCoupon(params) {
      // this.params.filename = params.filename ;
      this.params.description = params.description
      this.params.name = params.filename
      this.params.filter.app_id = ConfigManager.getAppId()
      this.params.filter.user_id = this.$route.params.id
      ;(this.params.filter.coupon_code = this.searchObject.serialcode),
        (this.params.filter.product = this.searchObject.product),
        (this.params.filter.from_date = this.searchObject.startdate
          ? this.searchObject.startdate
          : null),
        (this.params.filter.to_date = this.searchObject.enddate
          ? this.searchObject.enddate
          : null)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostReportCouponExport(this.params)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 335px);
  .ant-table-wrapper {
    min-width: 1210px !important;
    width: 100%;
  }
}
</style>
