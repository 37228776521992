<template>
  <AppLayout :menu-callcenter="true">
    <TitleBreadcrumbs class="mt-4 ml-5" title="Subject" />
    <div class="manage-subject flex-1 w-full px-4">
      <!-- <div
        v-if="datasetFeatures.includes('Manage-Subject')"
        class="mb-3 text-right createsub"
      >
        <a-button type="primary" @click="createNewSubject()"
          ><plus-outlined />Create New Subject</a-button
        >
      </div> -->
      <div class="mb-4 b-6 bg-white pl-4 pr-4">
        <div class="py-2 d-flex justify-content-between">
          <a-select
            class="select-status"
            v-model:value="statussearch"
            placeholder="All Status"
            :options="status_option"
            @change="clicksearch()"
          />

          <!-- <a-button type="primary" @click="clicksearch()" class="ml-4 mr-3"
            ><search-outlined />Search</a-button
          > -->
          <div class="border-l ml-4">
            <a-dropdown-button
              v-if="
                permissioncallcenter.find(x =>
                  x.features.find(y => y.name == 'Call-Center-Subject-Adjust'),
                )
              "
              overlayClassName="menu-create-subject"
              type="primary"
              @click="handleButtonClick"
            >
              Create Subject
              <template #overlay>
                <a-menu @click="handleMenuCreateClick">
                  <a-menu-item key="Subject"> Subject </a-menu-item>
                  <a-menu-item key="Topic Type"> Topic Type </a-menu-item>
                  <a-menu-item key="Topic Detail"> Topic Detail </a-menu-item>
                </a-menu>
              </template>
              <template #icon><DownOutlined /></template>
            </a-dropdown-button>
            <!-- <a-tooltip placement="bottom" class="ml-3">
              <template #title>
                <div>Create New Subject</div>
              </template>
              <a-button
                class="hover btn-icon"
                @click="createNewSubject()"
                :color="'blue-1'"
              >
                <img
                  class="left"
                  src="@/assets/icons/button/ico-plus.svg"
                  alt
                />
              </a-button>
            </a-tooltip> -->
          </div>
        </div>
      </div>
      <div class="wrap-table" @scroll="handleScroll">
        <!-- <VLoadingTable :value="overlaytable" /> -->
        <a-table class="fixed-head" :columns="columns" :pagination="false">
        </a-table>
        <a-collapse
          v-for="(item, index) in dataSubject.filter(
            x => !x.parent_problem_type_id,
          )"
          :key="item"
          v-model:activeKey="activeKey"
        >
          <template #expandIcon="{ isActive }">
            <caret-right-outlined
              v-if="topicType(item).length > 0"
              :rotate="isActive ? 90 : 0"
            />
          </template>
          <a-collapse-panel :key="index" :style="customStyle">
            <template #header>
              <div class="row" style="width: -webkit-fill-available">
                <div class="col-5">
                  <div class="d-flex">
                    <div>
                      <h4>
                        {{ item.type }}
                      </h4>
                    </div>
                    <a
                      v-if="
                        permissioncallcenter.find(x =>
                          x.features.find(
                            y => y.name == 'Call-Center-Subject-Adjust',
                          ),
                        )
                      "
                      class="text-link ml-2"
                      @click="
                        $event => showModalEditSubjet($event, item, 'Subject')
                      "
                      ><edit-outlined
                    /></a>
                  </div>
                </div>
                <div class="col-5"></div>
                <div class="col-2">
                  <template v-for="(itemstatus, index) in status">
                    <a-tag
                      v-if="item.is_show == itemstatus.name"
                      :key="index"
                      :color="
                        itemstatus.color == 'default' ? null : itemstatus.color
                      "
                      class="tag-status"
                    >
                      <a-badge
                        v-if="item.is_show == itemstatus.name"
                        :status="itemstatus.color"
                        :text="itemstatus.text"
                      />
                    </a-tag>
                  </template>
                </div>
              </div>
            </template>
            <div class="">
              <div
                class="row pb-type border-b"
                v-for="(itemtype, index2) in topicType(item)"
                :key="index2"
              >
                <div class="col-5 cursor-pointer" @click="clicktest(index2)">
                  <div class="mb-16">
                    <caret-right-outlined
                      @click="clicktest(true, index2)"
                      v-if="
                        !check[index2] &&
                        topicTypeDetail(itemtype.id).length > 0
                      "
                      class="icon"
                    />
                    <caret-down-outlined
                      @click="clicktest(false, index2)"
                      v-if="
                        check[index2] && topicTypeDetail(itemtype.id).length > 0
                      "
                      class="icon"
                    />
                    {{ itemtype.type }}
                    <a
                      class="text-link ml-2"
                      @click="
                        $event =>
                          showModalEditSubjet($event, itemtype, 'Topic Type')
                      "
                      ><edit-outlined
                    /></a>
                  </div>
                </div>
                <div class="col-5"></div>
                <div class="col-2">
                  <template v-for="(itemstatus, index) in status">
                    <a-tag
                      v-if="itemtype.is_show == itemstatus.name"
                      :key="index"
                      :color="
                        itemstatus.color == 'default' ? null : itemstatus.color
                      "
                      class="tag-status"
                    >
                      <a-badge
                        v-if="itemtype.is_show == itemstatus.name"
                        :status="itemstatus.color"
                        :text="itemstatus.text"
                      />
                    </a-tag>
                  </template>
                </div>
                <div v-if="check[index2]">
                  <div
                    class="row pb-detail border-t"
                    v-for="(itemtypedetail, index3) in topicTypeDetail(
                      itemtype.id,
                    )"
                    :key="index3"
                  >
                    <div class="col-5">
                      <div class="d-flex">
                        <div>
                          {{ itemtypedetail.type }}
                        </div>
                        <a
                          class="text-link ml-2"
                          @click="
                            $event =>
                              showModalEditSubjet(
                                $event,
                                itemtypedetail,
                                'Topic Detail',
                              )
                          "
                          ><edit-outlined
                        /></a>
                      </div>
                    </div>
                    <div class="col-5">
                      {{ itemtypedetail.basic_solution }}
                    </div>
                    <div class="col-2 pl-24">
                      <template v-for="(itemstatus, index) in status">
                        <a-tag
                          v-if="itemtypedetail.is_show == itemstatus.name"
                          :key="index"
                          :color="
                            itemstatus.color == 'default'
                              ? null
                              : itemstatus.color
                          "
                          class="tag-status"
                        >
                          <a-badge
                            v-if="itemtypedetail.is_show == itemstatus.name"
                            :status="itemstatus.color"
                            :text="itemstatus.text"
                          />
                        </a-tag>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="check" class="mt-3">
                <div class="row py-2 border-tb">
                  <div class="col-5">
                    <div class="content-col">
                      {{ topicTypeDetail() }}
                    </div>
                  </div>
                  <div class="col-5">{{ topicTypeDetailSolution() }}</div>
                  <div class="col-2">
                    <template v-for="(item, index) in status">
                      <a-tag
                        v-if="active == item.name"
                        :key="index"
                        :color="item.color == 'default' ? null : item.color"
                        class="tag-status"
                      >
                        <a-badge
                          v-if="active == item.name"
                          :status="item.color"
                          :text="item.text"
                        />
                      </a-tag>
                    </template>
                  </div>
                </div>
              </div> -->
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
    <a-modal
      :visible="modalEdit"
      okText="Save"
      wrap-class-name="modal-subject"
      :width="500"
      :centered="true"
      :closable="false"
      @ok="EditSubject"
      @cancel="closeModal"
      :ok-button-props="{ disabled: !itemSubject.type }"
    >
      <template #title>
        <div class="d-flex justify-between">
          <div>Edit {{ this.type }}</div>
          <div class="d-flex items-center gap-2">
            <a-switch
              class="mt-1"
              v-model:checked="itemSubject.is_show"
              checked-children="Active"
              un-checked-children="Inactive"
            />
          </div>
        </div>
      </template>
      <div><label class="false">*</label> {{ this.type }}</div>
      <a-input v-model:value="itemSubject.type" />
      <div class="mt-3" v-if="type == 'Topic Detail'">Basic Solution</div>
      <a-textarea
        v-if="type == 'Topic Detail'"
        v-model:value="itemSubject.basic_solution"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
    </a-modal>
    <a-modal
      :visible="modalCreate"
      okText="Save"
      :width="500"
      :centered="true"
      :closable="false"
      @ok="createSubject"
      @cancel="closeModal"
      :ok-button-props="{
        disabled:
          careate_type == 'Subject' && !newSubject
            ? true
            : careate_type == 'Topic Type' &&
              (!newSubjectSelect || !newTopictype)
            ? true
            : careate_type == 'Topic Detail' &&
              (!newSubjectSelect || !newToppicTypeSelect || !newTopicDetail)
            ? true
            : false,
      }"
    >
      <template #title>
        <div class="d-flex justify-between">
          <div>Create {{ careate_type }}</div>
          <!-- <div class="d-flex items-center gap-2">
            <div>Status</div>
            <a-tooltip placement="bottomRight">
              <template #title>
                <span v-if="isShow">Inactive</span>
                <span v-else>Active</span>
              </template>
              <a-switch class="mt-1" v-model:checked="isShow" size="small" />
            </a-tooltip>
          </div> -->
        </div>
      </template>
      <div class="mb-2"><label class="false">*</label>Subject</div>
      <a-input v-if="careate_type == 'Subject'" v-model:value="newSubject" />
      <a-select
        v-if="careate_type == 'Topic Type' || careate_type == 'Topic Detail'"
        v-model:value="newSubjectSelect"
        class="w-100"
        :options="options_newsubject"
        @change="selectSubject(newSubjectSelect)"
        placeholder="Please select subject"
      />
      <div
        v-if="careate_type == 'Topic Type' || careate_type == 'Topic Detail'"
        class="mt-3 mb-2"
      >
        <label class="false">*</label>Topic type
      </div>
      <a-input
        v-if="careate_type == 'Topic Type'"
        v-model:value="newTopictype"
      />
      <a-select
        v-if="careate_type == 'Topic Detail'"
        v-model:value="newToppicTypeSelect"
        class="w-100"
        :options="options_newtoppictype"
        placeholder="Please select topic detail"
      />
      <div v-if="careate_type == 'Topic Detail'" class="mt-3 mb-2">
        <label class="false">*</label>Topic detail
      </div>
      <a-input
        v-if="careate_type == 'Topic Detail'"
        v-model:value="newTopicDetail"
      />
      <div v-if="careate_type == 'Topic Detail'" class="mt-3">
        Basic Solution
      </div>
      <a-textarea
        v-if="careate_type == 'Topic Detail'"
        v-model:value="newBasicSolution"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
    </a-modal>
    <DisplayFieldsCreateSubject
      :value="showModalSubject"
      @clicksearch="clicksearch()"
      @handleClose="showModalSubject = false"
    >
    </DisplayFieldsCreateSubject>
    <DisplayFieldsEditSubject
      :value="showModalEditSubject"
      @handleClose="showModalEditSubject = false"
    >
    </DisplayFieldsEditSubject>
  </AppLayout>
</template>

<script>
import _ from 'lodash'
import AppLayout from '@/components/Layout/default.vue'
import DisplayFieldsCreateSubject from '@/module/CallCenterSetting/component/DisplayFieldsCreateSubject.vue'
import DisplayFieldsEditSubject from '@/module/CallCenterSetting/component/DisplayFieldsEditSubject.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    DisplayFieldsCreateSubject,
    DisplayFieldsEditSubject,
    AppLayout,
    TitleBreadcrumbs,
  },
  data: function () {
    return {
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      permissioncallcenter:
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket')),
      check: [],
      activeKey: null,
      dataSubject: [],
      columns: [
        {
          title: 'Subject',
          dataIndex: 'subject',
          slots: { customRender: 'subject' },
          key: 'subject',
          width: 500,
          align: 'left',
        },
        {
          title: 'Basic Solution',
          dataIndex: 'basic_solution',
          slots: { customRender: 'basic_solution' },
          key: 'basic_solution',
          width: 500,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 200,
          align: 'left',
        },
      ],
      showModalSubject: false,
      showModalEditSubject: false,
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
      active: true,
      statussearch: true,
      status_option: [
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      toppictyeid: '',
      checkTypeDetail: null,
      modalEdit: false,
      itemSubject: {},
      type: '',
      basic_solution: '',
      isShow: true,
      careate_type: '',
      modalCreate: false,
      newSubject: '',
      options_newsubject: [],
      newSubjectSelect: null,
      options_newtoppictype: [],
      newToppicTypeSelect: null,
      newBasicSolution: '',
      newTopictype: '',
      newTopicDetail: '',
    }
  },
  computed: {},
  mounted() {
    if (this.$store.state.permissionPage) {
      this.getSubject().then(() => {
        this.$store.commit('setLoading', false)
      })
    }
  },
  created() {},
  methods: {
    clicksearch() {
      this.getSubject().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    clicktest(index) {
      this.check[index] = !this.check[index]
    },
    topicType(item) {
      var toppictye = this.dataSubject.filter(
        x => x.parent_problem_type_id == item.id,
      )
      return toppictye
    },
    topicTypeDetail(id) {
      var toppictyedetail = this.dataSubject.filter(
        x => id == x.parent_problem_type_id,
      )
      return toppictyedetail
    },
    topicTypeDetailSolution() {
      var toppictyedetail = this.dataSubject.find(
        x => this.toppictyeid == x.parent_problem_type_id,
      )
      if (toppictyedetail) {
        return toppictyedetail.basic_solution
      }
    },
    handleMenuCreateClick(menu) {
      var itemSubject = this.dataSubject.filter(
        x => x.parent_problem_type_id == 0,
      )
      _.forEach(itemSubject, (item, index) => {
        this.options_newsubject[index] = {
          label: item.type,
          value: item.id,
        }
      })
      this.careate_type = menu.key
      this.newSubjectSelect = null
      this.newToppicTypeSelect = null
      this.newSubject = ''
      this.newTopictype = ''
      this.newTopicDetail = ''
      this.newBasicSolution = ''
      this.modalCreate = true
    },
    selectSubject(subject) {
      this.options_newtoppictype = []
      this.newToppicTypeSelect = null
      var toppictye = this.dataSubject.filter(
        x => x.parent_problem_type_id == subject,
      )
      _.forEach(toppictye, (item, index) => {
        this.options_newtoppictype[index] = {
          label: item.type,
          value: item.id,
        }
      })
    },
    createSubject() {
      this.$store.commit('setLoading', true)
      if (this.careate_type == 'Subject') {
        var dataSubject = {
          id: 0,
          type_name: this.newSubject,
          is_show: this.isShow,
          require_detail: false,
          parent_problem_type_id: 0,
          basic_solution: '',
        }
      }
      if (this.careate_type == 'Topic Type') {
        var datatopictype = {
          id: 0,
          type_name: this.newTopictype,
          is_show: this.isShow,
          require_detail: false,
          parent_problem_type_id: this.newSubjectSelect,
          basic_solution: '',
        }
      }
      if (this.careate_type == 'Topic Detail') {
        var datatopictypedetail = {
          id: 0,
          type_name: this.newTopicDetail,
          is_show: this.isShow,
          require_detail: false,
          parent_problem_type_id: this.newToppicTypeSelect,
          basic_solution: this.newBasicSolution,
        }
      }
      var data =
        this.careate_type == 'Subject'
          ? dataSubject
          : this.careate_type == 'Topic Type'
          ? datatopictype
          : datatopictypedetail
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateSubject(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.modalCreate = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getSubject().then(() => {
                  this.$store.commit('setLoading', false)
                })
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    showModalEditSubjet(event, item, type) {
      this.type = ''
      this.itemSubject = {}
      event.stopPropagation()
      this.type = type
      this.itemSubject = { ...item }
      this.modalEdit = true
    },
    closeModal() {
      this.itemSubject = {}
      this.newSubject = ''
      this.options_newsubject = []
      this.newSubjectSelect = null
      this.options_newtoppictype = []
      this.newToppicTypeSelect = null
      this.newBasicSolution = ''
      this.newTopictype = ''
      this.modalEdit = false
      this.modalCreate = false
    },
    EditSubject() {
      var data = {
        id: this.itemSubject.id,
        type_name: this.itemSubject.type,
        is_show: this.itemSubject.is_show,
        require_detail: this.type == 'Topic Detail' ? true : false,
        parent_problem_type_id: this.itemSubject.parent_problem_type_id,
        basic_solution:
          this.type == 'Topic Detail' ? this.itemSubject.basic_solution : '',
        AppId: null,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUpsertType(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.modalEdit = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.clicksearch()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    getSubject() {
      var appid = null
      var status = this.statussearch
      var is_call = true
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubjectList(appid, status, is_call).subscribe(
          res => {
            this.dataSubject = res.result
            this.$store.commit('setLoading', false)
            resolve(this.dataSubject)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    createNewSubject() {
      this.showModalSubject = !this.showModalSubject
    },
    toggleDisplayFieldsEdit(event) {
      event.stopPropagation()
      this.showModalEditSubject = !this.showModalEditSubject
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.manage-subject {
  .select-search {
    width: 200px;
  }
  .ant-table-tbody {
    display: none !important;
  }
  .ant-collapse {
    margin-top: 10px !important;
  }
  .ant-collapse-header {
    background-color: #ffffff !important;
    border-radius: 12px !important;
    padding: 16px !important;
  }
  .ant-collapse-content-box {
    background-color: #f9f9f9 !important;
    border-radius: 12px !important;
  }
  // .ant-collapse-borderless > .ant-collapse-item {
  //   border-bottom: none !important;
  // }
  .icon {
    margin-right: 12px;
    width: 12px;
    color: #797979;
  }
  .content-col {
    padding-left: 45px;
    // border-top: 1px solid #f0f0f0;
    // border-bottom: 1px solid #f0f0f0;
  }
  .border-tb {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  .h-col {
    margin: 0px 16px 0px 35px;
  }
  .createsub {
    position: absolute;
    right: 22px;
    top: 130px;
  }
  .wrap-table {
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
  .pl-50 {
    padding-left: 50px;
  }
  .pl-24 {
    padding-left: 5px;
  }
  .pb-type {
    padding: 16px 0px 0px 40px !important;
  }
  .pb-detail {
    padding: 16px 0px 16px 50px !important;
  }
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px 0px 0px !important;
  }
  .border-b {
    width: 100% !important;
  }
  .ant-btn-icon-only {
    min-width: 45px !important;
    color: #ffffff !important;
    background-color: #c68401 !important;
  }
}

.menu-create-subject {
  .ant-dropdown-content {
    width: 160px !important;
  }
}
.modal-subject {
  .ant-switch {
    background-color: var(--red-red-5) !important;
  }
  .ant-switch-checked {
    background-color: var(--green-green-5) !important;
  }
}
</style>
